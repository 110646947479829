// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    qa: true,
    dev: false,
    isDebugMode: true
};

let PROVIDERS: any[] = [
    /**
     * Common env directives
     */
];

PROVIDERS = [
    ...PROVIDERS,
    { provide: 'AUTH_URL', useValue: 'https://gateway-qa.phoenix-apothekenportal.de' },
    { provide: 'API_URL', useValue: 'https://gateway-qa.phoenix-apothekenportal.de' },
   // { provide: 'CMS_URL', useValue: 'https://phoenix-web-dev.twt-dh.de' },
    { provide: 'CMS_URL', useValue: 'https://cms.phoenix-apothekenportal.de'},
    { provide: 'ADMIN_MAIL', useValue: 'i.korlevic@phoenixgroup.eu' },
    { provide: 'REQ_DATE_FORMAT', useValue: 'yyyy-MM-dd' },
    { provide: 'VIEW_DATE_FORMAT', useValue: 'yyyy-MM-dd' },
    // deine Apotheke-Store providers
    { provide: 'DA_STORE_URL', useValue: 'https://phoenix-store.net/apologin'},
    { provide: 'DA_STORE_REFERRER', useValue: 'apothekenportal'},
    // Privatsortiment (Genuss Kultur) providers
    { provide: 'PS_URL', useValue: 'https://privatsortiment.de/apologin'},
    { provide: 'PS_REFERRER', useValue: 'apothekenportal'},
    // Notfalldepot providers
    {provide: 'ND_URL', useValue: 'https://deineapotheke-store.de/apologin'},
    {provide: 'ND_REFERRER', useValue: 'apothekenportal'},
    {provide: 'ND_FLAG', useValue: 'notfalldepot'},
    {provide: 'STORAGE', useValue: 'https://cspapssacc.blob.core.windows.net'},
    {provide: 'CDN', useValue: 'https://cspapssacc.blob.core.windows.net'},
    {provide: 'DATEV_REDIRECT', useValue:'https://web-qa.phoenix-apothekenportal.de/datev'},
    {provide: 'DATEV_LINK', useValue: 'https://login.datev.de/openidsandbox'},
    {provide: 'ADMIN_URL', useValue: 'https://admin-qa.phoenix-apothekenportal.de'},
    {provide: 'APP_URL', useValue: 'https://web-qa.phoenix-apothekenportal.de'},
    {provide: 'WEBCHAT', useValue: 'https://endpoint.live.ai.telekomcloud.com/5da86500df162c00232704ae83e15eb3c3e9f0b98345f6dae992443d98f53df7'}



    /**
     * Custom providers in development.
     */
];

export const ENV_PROVIDERS = [
    ...PROVIDERS
];

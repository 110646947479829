import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {MatomoService} from '../../../core/services/matomo.service';

@Component({
    selector: 'app-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {
    wizardPos = 1;
    funk: string;
    @Input() showWizard;
    @Output() hideWizard = new EventEmitter<boolean>();

    constructor(private matomoService: MatomoService) {
    }

    ngOnInit(): void {
    }

    appWizard(n) {
        this.wizardPos = n;
    }

    closeWizard() {
        this.showWizard = false;
        this.hideWizard.emit(true);
        this.wizardPos = 1;
    }

    mtmEvent(action) {
        this.matomoService.trackEvent('Klick auf AppWizard', action);
    }
}

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FirstLoginDialogComponent} from '../../modules/authentication/first-login-dialog/first-login-dialog.component';
import {SurveyDialogComponent} from '../../modules/shared/survey-dialog/survey-dialog.component';
import {DialogRestService} from '../../core/services/api/dialog.rest-service';
import {DialogService} from '../../core/services/dialog.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {MatomoService} from '../../core/services/matomo.service';
import {Observable, Subject} from 'rxjs';
import {takeUntil, takeLast, shareReplay} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FeedbackComponent} from '../../modules/shared/feedback/feedback.component';
import {AuthService} from '../../core/services/api/auth.service';
import {Location} from '@angular/common';
import {UserService} from '../../core/services/user.service';
import {CommunicationDashDialogComponent} from '../../modules/shared/communication-dash-dialog/communication-dash-dialog.component';
import {DialogData} from '../../core/interfaces/dialog-data.interface';
import {CommunicationRestService} from '../../core/services/api/communication.rest-service';
import { OpeningHoursDialogComponent } from '../../modules/shared/opening-hours-dialog/opening-hours-dialog.component';
import { AccountService } from '../../core/services/api/account.service';

@Component({
    selector: 'app-dialog-popup',
    templateUrl: './dialog-popup.component.html',
    styleUrls: ['./dialog-popup.component.scss']
})
export class DialogPopupComponent implements OnInit, OnDestroy {
    public hash = window.location.hash;
    showDialog = false;
    surveyRef: any;
    feedbackRef: any;
    communicationRef: any;
    openingHoursRef: any;
    private ngUnsubscribe$ = new Subject<any>();
    private dialogMap = new Map<string, any[]>();
    private previousUrl = '';
    loading = true;
    private selectedDialog = null;
    public userLoading$: Observable<boolean>; // getUserLoading
    private filter;
    private navigated = 0;
    private shownDialogs = [];
    errorRef: any;
    mainIdf = '';
    selectedIdf: any;

    constructor(
        private dialogRestService: DialogRestService,
        public dialog: MatDialog,
        public router: Router,
        public route: ActivatedRoute,
        private dialogService: DialogService,
        public matomoService: MatomoService,
        public userService: UserService,
        @Inject(AuthService) public auth: AuthService,
        private communicationService: CommunicationRestService,
        private location: Location,
        private accountService: AccountService
    ) {
        this.userService.getDistributionCentersLoadingObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((load: boolean) => {
            this.loading = load;
        });

        this.userService.getMainIdfObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(idf => {
            this.mainIdf = idf;
        })

        this.userService.selectedIdf$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(idf => {  
            if(idf) {    this.selectedIdf = idf;}})

        this.router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(value => {
            this.surveyRef = null;
            if (value instanceof NavigationEnd && !this.loading) {
                const hash = this.getFilter();
                this.filter = hash;

                if (this.auth.isAuthenticated()) {
                    if (this.previousUrl !== this.filter) {
                        if (this.previousUrl === 'login' || ['login', 'logout', 'register'].indexOf(this.filter) < 0) {
                            let dia = this;
                            setTimeout(() => {
                                dia.getDialog();
                            }, 600);
                            this.previousUrl = hash;
                            this.navigated++;
                        } else if (['login', 'logout', 'register'].indexOf(this.filter) < 0) {
                            this.getDialog();
                            this.previousUrl = hash;
                            this.navigated++;
                        }
                    }
                } else {
                    this.selectedDialog = null;
                }
            }

        });
    }

    ngOnInit(): void {
        if (this.previousUrl === '' && this.auth.isAuthenticated()) {

            const url = this.getFilter();
            this.filter = this.getFilter();
            this.previousUrl = url;
            this.getDialog();
        }
    }

    getFilter() {
        let url = window.location.hash.replace('#/', '');
        if (url.indexOf('/') === 0) {
            url = url.substring(1).trim();
        }

        if (url.indexOf('?') > -1) {
            url = url.substring(0, url.indexOf('?')).trim();
        }

        if (url.indexOf('%3D') > -1) {
            url = url.substring(0, url.indexOf('%3D')).trim();
        }

        return url;
    }


    getDialog() {
        if (this.filter !== null && this.selectedDialog === null) {
            this.dialogRestService.getActiveDialogsOnPage(this.filter).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(resp => {
                let response = resp.return_object;

                if (response !== undefined && response.length > 0) {
                    this.dialogService.setShowPopup(true);
                    if (sessionStorage.getItem('shownDialog') && !this.userService.isAdmin()) {
                        const existing = sessionStorage.getItem('shownDialog');
                        response = response.filter(res => existing.indexOf(res.id) < 0);
                    }


                    if (response.length === 1) {
                        this.selectedDialog = response[0];
                        if (this.selectedDialog.dialog_popup_type === 'FEEDBACK') {
                            this.showFeedback(this.selectedDialog);
                        } else if (this.selectedDialog.dialog_popup_type === 'COMMUNICATION') {
                            this.showCommunicationDialog(this.selectedDialog);
                        } else if (this.selectedDialog.dialog_popup_type === 'OPENING_HOURS') {
                            this.showOpeningHours(this.selectedDialog);
                        } else {
                            this.showSurvey(this.selectedDialog);
                        }
                    } else if (response.length > 1) {
                        this.selectedDialog = this.selectDialog(response);

                        if (this.selectedDialog.dialog_popup_type === 'FEEDBACK') {
                            this.showFeedback(this.selectedDialog);
                        } else if (this.selectedDialog.dialog_popup_type === 'COMMUNICATION') {
                            this.showCommunicationDialog(this.selectedDialog);
                        } else if (this.selectedDialog.dialog_popup_type === 'OPENING_HOURS') {
                            this.showOpeningHours(this.selectedDialog);
                        } else {
                            this.showSurvey(this.selectedDialog);
                        }
                    } else {
                        this.selectedDialog = null;
                    }
                    this.filter = null;
                }
            }, error => {
                // console.log('do not show dialog');
            });
        }
    }
    

    showFirstLoginDialog() {
        const firstLoginDialogConfig = new MatDialogConfig();
        firstLoginDialogConfig.width = '50vw';
        const dialogRef = this.dialog.open(FirstLoginDialogComponent, firstLoginDialogConfig);
    }


    showSurvey(data) {
        if (this.dialogService.getShowPopup()) {
            this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'PopUp Gesehen', data.title);
            this.surveyRef = this.dialog.open(SurveyDialogComponent,
                {
                    maxWidth: '600px',
                    data: data,
                    autoFocus: false,
                    closeOnNavigation: true
                });

            this.surveyRef.afterClosed().subscribe(result => {
                this.selectedDialog = null;
                this.navigated = 0;
            });

            if (data.subscription === false) {
                this.shownDialogs.push(data.id);
                sessionStorage.setItem('shownDialog', this.shownDialogs.join(','));
            }
            this.dialogService.setShowPopup(false);
            this.navigated = 0;
        }
    }


    showFeedback(data) {
        if (this.dialogService.getShowPopup()) {
            this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'PopUp Gesehen', data.title);
            this.feedbackRef = this.dialog.open(FeedbackComponent,
                {
                    maxWidth: '600px',
                    data: data,
                    autoFocus: false,
                    closeOnNavigation: true
                });

            this.feedbackRef.afterClosed().subscribe(result => {
                this.selectedDialog = null;
                this.navigated = 0;
            });
            this.dialogService.setShowPopup(false);
            this.navigated = 0;
        }
    }

    closeSurvey() {
        this.surveyRef.close();
        this.selectedDialog = null;
    }

    closeFeedback() {
        this.feedbackRef.close();
        this.selectedDialog = null;
    }


    selectDialog(dialogs) {
        const selected = [];

        const highestPrio = dialogs[0].priority;
        const prioritized = dialogs.filter(d => d.priority === highestPrio);

        if (prioritized.length === 1) {
            return prioritized[0];
        }
        selected.push(prioritized[Math.floor(prioritized.length * Math.random())]);

        console.log(selected);
        return selected[0];
    }

    onSuccess($event) {
        console.log($event);
    }

    private showCommunicationDialog(data: any) {
        if (this.dialogService.getShowPopup() && this.mainIdf) {
            this.accountService.getOpeningHours(this.mainIdf).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(configs => {
                if(configs.returnObject.length === 0) {
                    this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'PopUp Gesehen', data.title);
                    this.communicationRef = this.dialog.open(CommunicationDashDialogComponent,
                        {
                            maxWidth: '750px',
                            data: data,
                            autoFocus: false,
                            closeOnNavigation: true,
                            disableClose: true
                        });

                    this.communicationRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
                        this.selectedDialog = null;
                        this.navigated = 0;
                        this.shownDialogs.push(data.id);
                        sessionStorage.setItem('shownDialog', this.shownDialogs.join(','));
                    });
                    this.dialogService.setShowPopup(false);
                    this.navigated = 0;
                }
            })
        }
    }

    showOpeningHours(selectedDialog: any) {
        if (this.dialogService.getShowPopup()) {
            console.log('show opening hours');
            this.accountService.getOpeningHours(this.selectedIdf.idf).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(configs => {
                    this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'PopUp Gesehen', selectedDialog.title);
                    selectedDialog['account'] = this.selectedIdf;
                    console.log(selectedDialog);
                    this.openingHoursRef = this.dialog.open(OpeningHoursDialogComponent,
                        {
                            maxWidth: '800px',
                            data: selectedDialog,
                            autoFocus: false,
                            closeOnNavigation: true,
                            disableClose: true
                        });

                    this.openingHoursRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
                        this.selectedDialog = null;
                        this.navigated = 0;
                        this.shownDialogs.push(selectedDialog.id);
                        sessionStorage.setItem('shownDialog', this.shownDialogs.join(','));
                    });
                    this.dialogService.setShowPopup(false);
                    this.navigated = 0;
                }
            )}
    }
    

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.feedbackRef?.close();
        this.communicationRef?.close();
        this.surveyRef?.close();
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
        this.selectedDialog = null;
    };

}

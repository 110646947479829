<div id="login" *ngIf="!loading">
    <section *ngIf="isIe" class="panel-body d-flex align-items-center mb-xl position-relative">
        <div class="col-md-2 text-center">
            <img src="assets/img/no-ie.png" class="img-fluid">
        </div>
        <div class="col-md-10">
            <h2>Der Internet Explorer ist veraltet und wird nicht mehr unterstützt!</h2>
            <p>
                Einige Funktionen oder Inhalte auf dieser Seite können mit Ihrem Browser möglicherweise nicht richtig dargestellt werden.
                Damit Sie das PHOENIX Apothekenportal uneingeschränkt nutzen können, empfehlen wir Ihnen die Verwendung eines aktuellen Browsers
                wie beispielsweise:
            </p>
            <ul class="list-unstyled">
                <li>
                    <a href="https://www.google.de/chrome/" target="_blank" rel="noopener">Google Chrome</a>
                </li>
                <li>
                    <a href="https://www.microsoft.com/de-de/edge" target="_blank" rel="noopener">Microsoft Edge</a>
                </li>
                <li>
                    <a href="https://www.mozilla.org/de/firefox/new/" target="_blank" rel="noopener">Mozilla Firefox</a>
                </li>
            </ul>
        </div>
    </section>



  <app-slider></app-slider>
  <!--    <app-home-notify></app-home-notify>-->
  <app-notices noticeLocation="login"></app-notices>

    <div *ngIf="error || action" class="panel-error">
        <notification-public *ngIf="error"
                             [html]="'<p>Ihr Login war leider nicht erfolgreich. Bitte wiederholen Sie die Eingabe und achten Sie auf die korrekte Schreibweise von Benutzername und Passwort.</p>'"
                             [type]="'error'">
        </notification-public>
        <notification-public *ngIf="action && action == 'passwort-erfolgreich-gesetzt'"
                             message="Ihr neues Passwort wurde gespeichert. Geben Sie Ihren Benutzernamen und Ihr Passwort hier ein."
                             type="success">
        </notification-public>
    </div>

    <app-wizard *ngIf="error || action" [showWizard]="showWizard" (hideWizard)="resetWizard()"></app-wizard>

    <div class="panel-body mb-xxxl" *ngIf="!isIe">
        <div class="row justify-content-md-center">
            <div class="col-6 col-md-4 col-xl-2 pl-xxl pr-xl pb-l">
                <span *ngIf="isQaVisible()" class="qaText">QA - Umgebung</span>
                <img class=" login-logo" src="assets/img/phoenix-logo_corp-login.svg" alt="PHOENIX Apothekenportal logo">
            </div>
            <div class="col-xl-10 col-sm-12">
                <div *ngIf="loading">
                    <div class="text-center py-l">
                        <i class="fal fa-spinner-third fa-spin"></i>&nbsp;Ihre Benutzerinformationen werden überprüft.
                    </div>
                </div>
                <form [formGroup]="loginForm" (submit)="submit()" *ngIf="!loading" [class.submitted]="submitted"
                      id="mtm-logformconv">
                    <div class="row">
                        <div class="col-md-6 pr-xxl pb-5 pb-md-0 pl-0">
                            <h3 class="pb-0">Apothekenportal Login</h3>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Benutzername (max.mustermann)</mat-label>
                                <input autocomplete="off" data-cy="username" formControlName="username" matInput
                                       tabindex="1">
                                <mat-icon #tooltip="matTooltip" (click)="tooltip.toggle();$event.stopPropagation()"
                                          [matTooltipClass]="'multiline'"
                                          class="far fa-question-circle px-1" matSuffix
                                          matTooltip="Ihren Benutzernamen haben Sie bei der Registrierung in der Bestätigungsmail erhalten. Dieser setzt sich zusammen aus Ihrem Vornamen und Nachnamen. Beispiel: max.mustermann&#13;
                                  Die Eingabe Ihrer IDF-Nummer oder E-Mail-Adresse ist nicht möglich."
                                          matTooltipPosition="above">
                                </mat-icon>
                                <mat-error *ngIf="username.hasError('required') && submitted">
                                    Bitte geben Sie Ihren Benutzernamen ein.
                                </mat-error>
                                <mat-error *ngIf="username.hasError('noIdf')">
                                    Die Eingabe Ihrer IDF-Nummer ist nicht möglich. Bitte geben Sie Ihren Benutzernamen
                                    ein, z. B. max.mustermann.
                                </mat-error>
                                <mat-error *ngIf="username.hasError('noEmail')">
                                    Die Eingabe Ihrer Emailadresse ist nicht möglich. Bitte geben Sie Ihren
                                    Benutzernamen ein, z. B. max.mustermann.
                                </mat-error>
                                <mat-error *ngIf="username.hasError('pattern')">
                                    Das Benutzernamenformat ist falsch (z. B. max.mustermann)
                                </mat-error>

                                <mat-error *ngIf="error || action">
                                    Das Benutzernamenformat ist falsch (z. B. max.mustermann)
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Passwort</mat-label>
                                <input [type]="hide ? 'password' : 'text'" autocomplete="off" data-cy="password"
                                       formControlName="password"
                                       matInput tabindex="2">
                                <mat-icon (click)="hide = !hide" matSuffix>{{hide ? 'visibility_off' : 'visibility'}}
                                </mat-icon>
                                <mat-error *ngIf="password.hasError('required') && submitted">Bitte geben Sie Ihr
                                    Passwort ein.
                                </mat-error>
                                <mat-error *ngIf="error || action">Bitte geben Sie Ihr
                                    Passwort ein.
                                </mat-error>
                            </mat-form-field>
                            <div class="d-flex justify-content-between align-items-center mt-m">
                                <button class="btn-primary m-0" data-cy="login-submit" id="mtm-log"
                                        tabindex="3"
                                        type="submit">
                                    Einloggen
                                </button>
                                <a id="mtm-zv" routerLink="/zugangsdaten-vergessen" tabindex="5">Zugangsdaten
                                    vergessen?</a>
                            </div>

                        </div>
                        <div class="col-md-6 border-left pl-xxl">

                            <h3 class="pb-m">Registrierung</h3>
                            <p>Registrieren Sie sich als PHOENIX Großhandelskunde kostenfrei und profitieren Sie von den
                                zahlreichen Vorteilen des
                                Apothekenportals.</p>
                            <a id="mtm-reg" routerLink="/registrierung" tabindex="4">zur Registrierung <i
                                    class="far fa-angle-right"></i>
                            </a>
                            <h3 class="pt-xxl pb-m">Sie benötigen Unterstützung?</h3>
                            <a [queryParams]="{url: '/hilfe', heading: 'Häufig gestellte Fragen'}"
                               [routerLink]="'/open/static-content'"
                               id="mtm-hlf" tabindex="4" target="_blank">häufig gestellte Fragen <i
                                    class="far fa-angle-right"></i></a>
                            <br>
                            <a (click)="openDialog()" class="link" tabindex="4">zum Erklär-Video <i
                                    class="far fa-angle-right"></i>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

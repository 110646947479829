export interface PreOrderCategoryDetails {
    name: string;
    headerText: string;
    archiveDisplayName: string;
    description: string;
    config: Config;
    vendors: PreOrderSupplierModel[];
}

export interface Config {
    labelPzn: string;
    sortingPzn: number;
    labelProductName: string;
    sortingProductName: number;
    labelQuantity: string;
    sortingQuantity: number;
    labelDoctorsCount: string;
    sortingDoctorsCount: number;
    showDoctorsCount: boolean;
    labelBedarfsmenge: string;
    sortingBedarfsmenge: number;
    showBedarfsmenge: boolean;
    labelInhalt: string;
    sortingInhalt: number;
    showInhalt: boolean;
    labelAep: string;
    sortingAep: number;
    showAep: boolean;
    labelTier1: string;
    sortingTier1: number;
    showTier1: boolean;
    labelVorteilAufRap: string;
    sortingVorteilAufRap: number;
    showVorteilAufRap: boolean;
}

export interface SupplierProduct {
    pzn: string;
    preOrderPeriodId: number;
    name: string;
    unit: string;
    comment: string;
    sorting: number;
    active: boolean;
    type:  'NET' | 'NR' | 'UNKNOWN';
    priceNormalAEP: number;
    priceTier1: number;
    priceTier2: number;
    priceTier3: number;
    amountTier1: number;
    amountTier2: number;
    amountTier3: number;
    advantage: number;
    quantity?: number;
    doctorsCount?: number;
}

export class PreOrderSupplierModel {
    vendor: string;
    vendorImage: string;
    start: string;
    end: string;
    shopEnd: string;
    showDates: boolean;
    preOrderPeriodId: number;
    products: SupplierProduct[];
    alert: string;
    disclaimer: string;
    hint: string;

    constructor(obj: any = {}) {
        this.vendor = obj.vendor;
        this.vendorImage = obj.vendorImage;
        this.start = obj.start;
        this.end = obj.end;
        this.shopEnd = obj.shopEnd;
        this.showDates = obj.showDates;
        this.preOrderPeriodId = obj.preOrderPeriodId;
        this.products = obj.products;
        this.alert = obj.alert;
        this.disclaimer = obj.disclaimer;
        this.hint = obj.hint;
    }
}

import {Injectable} from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import {NavService} from '../nav.service';
import {Observable} from 'rxjs';
import {UserService} from '../user.service';
import {catchError, map, take} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {FluShopService} from '../flu-shop.service';

/**
 * this service can be used with any route canActivate i.e retouren.module.ts
 */

@Injectable()
export class RouteGuardPreorderService implements CanActivate {

    constructor(public navService: NavService,
                private userService: UserService,
                public fluShopService: FluShopService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.router.navigated) {
            return this.fluShopService.loadActivePreorderCategories().pipe(map((res) => {
                    const preorderCategory = res.find((item) => state.url.includes(item.url));
                    return !!preorderCategory;
                }),
                catchError((error) => of(false))
            );
        } else {
            const activeCategories = this.fluShopService.getActiveCategories();
            const preorderCategory = activeCategories.find((item) => state.url.includes(item.url));
            return !!preorderCategory;
        }
    }
}

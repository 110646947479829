import {Injectable} from '@angular/core';

@Injectable()
export class CalendarService {
    // TODO: Test getWeekOfTheYear
    getWeekOfTheYear() {
        const d = new Date();
        d.setHours(0, 0, 0);
        d.setDate(+d.getDate() + 4 - (+d.getDay() || 7));
        return Math.ceil((((+d - +new Date(+d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
    }

    getLast12MonthsArray(): string[] {
        const date = new Date();
        const months = [],
            monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
                'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

        date.setDate(1);
        for (let i = 0; i <= 11; i++) {
            months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear());
            date.setMonth(date.getMonth() - 1);
        }
        return months;
    }

    /**
     * TODO: Test getDateWithFirstDayOfMonthCountMonthsBack
     * @param count
     */
    getDateWithFirstDayOfMonthCountMonthsBack(count: number): Date {
        const date = new Date(), y = date.getFullYear(), m = date.getMonth();
        const newStartDate = new Date(y, m, 1);
        newStartDate.setMonth(new Date().getMonth() - count);
        return newStartDate;
    }

    /**
     * TODO: Test getDateWithLastDayOfMonthCountMonthsBack
     * @param count
     */
    getDateWithLastDayOfMonthCountMonthsBack(count: number): Date {
        const date = new Date(), y = date.getFullYear(), m = date.getMonth();
        const newEndDate = new Date(date.getFullYear(), (date.getMonth() - count) + 1, 0);
        return newEndDate;
    }

    isToday(date: Date) {
        date = new Date(date);
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }

    getOneYearOldDate() {
        const d = new Date();
        let start = new Date();
        start.setFullYear(d.getFullYear()-1);
        console.log(d);
        return d;
    }

    getLast3MonthsOfEvaluationsArray(): string[] {
        const date = new Date();
        const months = [],
            monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
                'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

        if (date.getDate() < 5) {
            date.setMonth(date.getMonth() - 2);
        } else {
            date.setMonth(date.getMonth() - 1);
        }

        for (let i = 0; i <= 1; i++) {
            months.push(monthNames[date.getMonth()] + ' ' + date.getFullYear());
            date.setMonth(date.getMonth() - 1);
        }
        return months;
    }
}

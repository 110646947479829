import {Directive, EventEmitter, Inject, Injectable, Output} from '@angular/core';
import {CartItem} from '../models/shop/cart-item.model';
import {select, Store} from '@ngrx/store';
import * as fromReducers from '../store/reducers';
import {
    AddCartItemAction,
    ClearCartAction,
    RemoveCartItemAction,
    UpdateCartItemAction,
    UpdateCartItemsAction
} from '../store/actions/cart.actions';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Totals} from '../models/totals.model';
import {WebshopRestService} from './api/webshop-rest.service';
import {OrderRequest} from '../models/shop/order-request.model';
import {LogService} from './utility/log.service';
import {ProductType} from '../models/shop/product-type.model';

@Directive()
@Injectable()
export class CartService {
    @Output() add = new EventEmitter<CartItem[]>();
    @Output() update = new EventEmitter<CartItem>();
    @Output() remove = new EventEmitter<string>();
    @Output() clear = new EventEmitter<any>();
    public cartItems$: Observable<CartItem[]>;
    public totals$: Observable<Totals>;
    public latestItems$: Observable<CartItem[]>;
    public cartItems: CartItem[] = [];
    private ngUnsubscribe$ = new Subject<any>();

    /**
     * @param {Store} store
     * @param msv3Service
     */
    constructor(public store: Store<fromReducers.State>,
                @Inject(LogService) public log: LogService,
                @Inject(WebshopRestService) public msv3Service: WebshopRestService) {
        this.add.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value) => {
            const addCartItemAction: AddCartItemAction = new AddCartItemAction(value);
            this.store.dispatch(addCartItemAction);
        });
        this.update.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value) => {
            const updateCartItemAction: UpdateCartItemAction = new UpdateCartItemAction(value);
            this.store.dispatch(updateCartItemAction);
        });
        this.remove.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((pzn) => {
            const removeFromCartAction: RemoveCartItemAction = new RemoveCartItemAction(pzn);
            this.store.dispatch(removeFromCartAction);
        });
        this.clear.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
            const clearCartAction: ClearCartAction = new ClearCartAction();
            this.store.dispatch(clearCartAction);
        });
        this.totals$ = this.store.pipe(select(fromReducers.getTotals));
        this.cartItems$ = this.store.pipe(select(fromReducers.getCartItems));
        this.latestItems$ = this.store.pipe(select(fromReducers.getLatestCartItems));
        this.cartItems$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((items) => {
            this.cartItems = items;
        });
    }

    /**
     * @return {Observable} cartItems$
     */
    getCartItemsObservable(): Observable<CartItem[]> {
        return this.cartItems$;
    }

    getLatestCartItemsObservable(): Observable<CartItem[]> {
        return this.latestItems$;
    }

    /**
     * @returns {CartItem[]} cartItems
     */
    getCartItems(): CartItem[] {
        return this.cartItems;
    }

    /**
     * @param {String} pzn
     * @returns {CartItem} cartItem
     */
    getCartItemByPzn(pzn: string): CartItem {
        let cartItem: CartItem = new CartItem();
        this.cartItems.forEach((item) => {
            if (pzn === item.product.id) {
                cartItem = item;
            }
        });
        return cartItem;
    }

    /**
     * Emits add event.
     * @param {CartItem} item
     */
    addToCart(items: CartItem[]): void {
        this.add.next(items);
    }

    /**
     * Emits update event.
     * @param {CartItem} item
     */
    updateItem(item: CartItem): void {
        this.update.next(item);
    }

    /**
     * Emits update event.
     * @param {CartItem[]} items
     */
    updateItems(items: CartItem[]): void {
        this.store.dispatch(new UpdateCartItemsAction(items));
    }

    /**
     * Emits remove event.
     * @param {String} pzn
     */
    removeFromCart(pzn: string): void {
        this.remove.next(pzn);
    }

    /**
     * Emits clear event.
     */
    clearCart(): void {
        this.clear.next();
    }

    //TODO: Create type when it is clear what it's going to be and implement
    send(request: OrderRequest) {
        return this.msv3Service.order(request);
    }

    /**
     * @return {Totals}
     */
    calculateTotals(): Totals {
        const totals = new Totals();
        const cart = this.getCartItems();
        for (let i = 0; i < cart.length; i++) {
            const item = cart[i];
            totals.total += item.price * item.quantity;
            if (item.product.type === ProductType.Payback) {
                totals.paybackTotal += item.price * item.quantity;
            }
            totals.quantityTotal += parseInt(item.quantity.toString());
            if (item.product.shipping && item.product.shipping > totals.shipping) {
                totals.shipping = item.product.shipping;
            }
        }
        totals.shipping = totals.paybackTotal <= 20 ? totals.shipping : 0;
        return totals;
    }

    /**
     * @return {CartItem}
     */
    itemsAvailable(): CartItem[] {
        let subscriptions = [];
        let items = this.getCartItems();
        if (items.length == 0) {
            return [];
        }
        let updatedItems = [];
        for (let i in items) {
            let item = items[i];
            let pzn = [item.product.id + ':' + item.quantity];
            subscriptions[i] = this.msv3Service.loadDetailedAvailability(pzn);
            subscriptions[i].subscribe((availability => {
                item.availability = availability.returnObject;
                updatedItems.push(item);
            }));
        }
        this.updateItems(updatedItems);
        return updatedItems;
    }

    /**
     * @param {CartItem} item
     * @return {CartItem} item
     */
    itemAvailable(item: CartItem): CartItem {
        if (!item) {
            return;
        }
        if (item.product && !item.product.canCallAvailability) {
            item.availability = {};
        }

        if(item.product && item.product.type === ProductType.Promo){
            item.availability = {};
            return item;
        }

        this.log.info('CartService:itemAvailable:item', item);
        let pzn = [item.product.id + ':' + item.quantity];
        let subscription = this.msv3Service.loadDetailedAvailability(pzn);
        subscription.subscribe((availability => {
            item.availability = availability.returnObject;
        }));
        return item;
    }

    getTotalsObservable() {
        return this.totals$;
    }

    getProductTypesObservable() {
        return this.store.pipe(select(fromReducers.getProductTypes));
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

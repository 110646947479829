import {Inject, Injectable, OnDestroy} from '@angular/core';
import {TrackItem} from '../models/shop/track-item.model';
import {Observable, Subject} from 'rxjs';
import {TrackingRestService} from './api/tracking-rest-service';
import {TrackRequestModel} from '../models/shop/track-request.model';
import * as fromReducers from '../store/reducers';
import {select, Store} from '@ngrx/store';
import {LoadDelaysAction, StartTrackAction, StartTrackIdfAction} from '../store/actions/track.actions';
import {TrackIdfRequestModel} from '../models/shop/track-idf-request.model';


const dummy = {
    customerIdentification: {customerNo: 7812, branchNo: 5},
    tourInformation: {
        tour: [
            {
                delayInformation: 'keine Verspätung',
                delayInMinutes: -7,
                deliveredAt: null,
                expectedTimeOfArrivalMax: '2020-12-03T13:00:00',
                expectedTimeOfArrivalMin: '2020-12-03T12:50:00',
                plannedTimeOfArrival: '2020-12-03T13:00:00',
                tourId: 121526
            },
            {
                delayInformation: 'keine Verspätung',
                delayInMinutes: 7,
                deliveredAt: null,
                expectedTimeOfArrivalMax: '2020-12-03T18:07:00',
                expectedTimeOfArrivalMin: '2020-12-03T18:00:00',
                plannedTimeOfArrival: '2020-12-03T18:00:00',
                tourId: 141114
            },
            {
                delayInformation: 'Verspätung',
                delayInMinutes: 17,
                deliveredAt: null,
                expectedTimeOfArrivalMax: '2020-12-03T19:07:00',
                expectedTimeOfArrivalMin: '2020-12-03T18:57:00',
                plannedTimeOfArrival: '2020-12-03T18:50:00',
                tourId: 121526
            },
            {
                delayInformation: 'Verspätung',
                delayInMinutes: 47,
                deliveredAt: null,
                expectedTimeOfArrivalMax: '2020-12-03T19:17:00',
                expectedTimeOfArrivalMin: '2020-12-03T19:07:00',
                plannedTimeOfArrival: '2020-12-03T18:20:00',
                tourId: 121526
            }
        ]
    }
};

@Injectable({
    providedIn: 'root',
})
export class TrackTraceService implements OnDestroy {
    public trackItems: TrackItem[] = [];
    public trackItems$ = new Subject<TrackItem[]>();
    private ngUnsubscribe$ = new Subject<any>();
    private subject = new Subject<any>();


    constructor(public store: Store<fromReducers.State>,
                @Inject(TrackingRestService) public restService: TrackingRestService
    ) {
    }

    startTracking(branch, idf, date) {
        const request = new TrackRequestModel(branch, idf, date, true, null);
        this.store.dispatch(new StartTrackAction(request));
    }

    startIdfTracking(idf, date) {
        const request = new TrackIdfRequestModel(idf, date, true, null);
        this.store.dispatch(new StartTrackIdfAction(request));
    }

    startAllTracking(idf, date, details) {
        const request = new TrackIdfRequestModel(idf, date, details, null);
        this.store.dispatch(new StartTrackIdfAction(request));
    }

    getTrackLoadingObservable(): Observable<boolean> {
        return this.store.pipe(select(fromReducers.getTrackLoading));
    }

    getTrackErrorObservable(): Observable<any> {
        return this.store.pipe(select(fromReducers.getTrackErrorResponse));
    }

    getTrackItems(): Observable<TrackItem[]> {
        return this.store.pipe(select(fromReducers.getTrackResponse));
    }

    sendDetails(item: TrackItem) {
        return this.subject.next(item);
    }

    onSendDetails() {
        return this.subject.asObservable();
    }

    checkDelay() {
        this.store.dispatch(new LoadDelaysAction());
        return this.store.pipe(select(fromReducers.getDelay))
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

<mat-dialog-content>
<div class="container">
<div class="row">
<h3 class="mt-xxs">Hinterlegen Sie die Öffnungszeiten Ihrer Apotheke:</h3>
</div>
    <p *ngIf="success">Opening hours are saved</p>
    <p *ngIf="error">Opening hours are not saved</p>
<div>
  <i class="far fa-store-alt phx-corporate pl-0"></i>
      <p class="mb-xs">{{data.account.name}}</p>
  <table class="idf-select-table table-bordered-rounded">
    <tr></tr>
    <tr class="table-head">
      <td class="pr-s table-cell-rounded"><strong>IDF</strong></td>
      <td class="table-cell-rounded"><strong>Adresse</strong></td>
    </tr>
    <tr class="table-body">
      <td class="pr-s table-cell-rounded">{{data.account.idf}}</td>
      <td class="table-cell-rounded">{{data.account.street}},&nbsp;{{data.account.zipCode}}&nbsp;{{data.account.city}}</td>
    </tr>
  </table>
</div>
<form [formGroup]="openingHoursForm">
  <div *ngFor="let day of weekDays">
    <div class="row">
      <h4>{{ day.label }}</h4>      
    </div>    
    <div class="row">
      <mat-checkbox formControlName="{{ day.name }}-closed" (change)="toggleClosed(day.name)">Geschlossen</mat-checkbox>
    </div>
    <div formArrayName="{{ day.name }}-times" *ngIf="!isClosed(day.name)" style="display: flex; align-items: center; gap: 10px;" >
          <div class="row">
          <div *ngFor="let time of getTimeControls(day.name).controls; let i = index" [formGroupName]="i" style="display: flex; align-items: center; gap: 5px;" class="col-md ">
            <mat-form-field class="example-form-field" appearance="outline">
              <mat-label>Öffnet um:</mat-label>
              <input formControlName="open" matInput>
            </mat-form-field>
            -
            <mat-form-field class="example-form-field" appearance="outline">
              <mat-label>Schließt um:</mat-label>
              <input formControlName="close" matInput>
            </mat-form-field>
          </div>
          </div>
          
          <div class="col-md">
            <button type="button" (click)="addTimeSlot(day.name)" *ngIf="getTimeControls(day.name).length < 2" class="btn-round mr-3">+</button>
            <button type="button" (click)="removeTimeSlot(day.name, 1)" *ngIf="getTimeControls(day.name).length === 2" class="btn-round mr-3"><i class="fal fa-trash"></i></button>
          </div>
        </div>
  </div>

  <button class="btn btn-primary" (click)="save()" >Speichern</button>
</form>
</div>
</mat-dialog-content>

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {Action} from '@ngrx/store';
import * as fromSubsequentDeliveriesActions from '../actions/subsequent-deliveries.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {SubsequentDeliveriesRestService} from '../../services/api/subsequent.deliveries.rest-service';
import {ProductsResponse} from '../../models/shop/products-response.model';
import {WebshopRestService} from '../../services/api/webshop-rest.service';
import {ProductType} from '../../models/shop/product-type.model';

@Injectable()
export class SubsequentDeliveriesEffects {

    constructor(private actions$: Actions,
                private subsequentDeliveriesRestService: SubsequentDeliveriesRestService,
                private productRestService: WebshopRestService) {
    }

    loadSubsequentDeliveries$: Observable<Action> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromSubsequentDeliveriesActions.LoadSubsequentDeliveries>(
                fromSubsequentDeliveriesActions.SubsequentDeliveriesActionsType.LOAD_SUBSEQUENT_DELIVERIES))
            .pipe(map(action => action.payload),
                switchMap((subsequentDeliveriesRequest) =>
                    this.subsequentDeliveriesRestService.loadSubsequentDeliveries(subsequentDeliveriesRequest)
                        .pipe(
                            map((data) => new fromSubsequentDeliveriesActions.LoadSubsequentDeliveriesCompleted(data.returnObject)),
                            catchError((error) => of(new fromSubsequentDeliveriesActions.FailSubsequentDeliveries(error)))
                        )
                )
            );
    });

    searchProduct$: Observable<any> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromSubsequentDeliveriesActions.SearchShopAction>(
                fromSubsequentDeliveriesActions.SubsequentDeliveriesActionsType.SEARCH_PRODUCT))
            .pipe(map(action => action.payload),
                switchMap((productRequest) => [new fromSubsequentDeliveriesActions.LoadProducts(productRequest)]));
    });

    loadProductsSuggestion$: Observable<any> = createEffect(() => {
        return this.actions$
            .pipe(ofType<fromSubsequentDeliveriesActions.LoadProducts>(
                fromSubsequentDeliveriesActions.SubsequentDeliveriesActionsType.LOAD_PRODUCTS))
            .pipe(map(action => action.payload), switchMap((productRequest) =>
                this.productRestService.loadSubsequentDelivery(productRequest).pipe(
                    switchMap(data => {
                        const prodResp = new ProductsResponse({...data.returnObject, ...{type: ProductType.SubsequentDelivery}});
                        return [new fromSubsequentDeliveriesActions.LoadProductsCompleted(prodResp)];
                    }),
                    catchError((error) => of(new fromSubsequentDeliveriesActions.LoadProductsFailed(error)))
                )
            ));
    });
}
import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AccountService} from '../../../core/services/api/account.service';
import {UserService} from '../../../core/services/user.service';
import {takeUntil, takeLast, shareReplay} from 'rxjs/operators';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-opening-hours-dialog',
  templateUrl: './opening-hours-dialog.component.html',
  styleUrls: ['./opening-hours-dialog.component.scss']
})
export class OpeningHoursDialogComponent implements OnInit {
  openingHoursForm: FormGroup;
  timeSlots: string[] = [];
  private ngUnsubscribe$ = new Subject<any>();
  private idf;

  weekDays = [
    { name: 'monday', label: 'Montag' },
    { name: 'tuesday', label: 'Dienstag' },
    { name: 'wednesday', label: 'Mittwoch' },
    { name: 'thursday', label: 'Donnerstag' },
    { name: 'friday', label: 'Freitag' },
    { name: 'saturday', label: 'Samstag' },
    { name: 'sunday', label: 'Sonntag' }
  ];
  public success: boolean;
  public error: boolean;

  constructor(private fb: FormBuilder, 
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<OpeningHoursDialogComponent>,
              private accountService: AccountService,
              private userService: UserService){

              this.openingHoursForm = this.fb.group({});
              this.generateTimeSlots();

  }

  ngOnInit(): void {
    this.weekDays.forEach(day => {
      this.openingHoursForm.addControl(day.name + '-closed', new FormControl(false));
      const timesArray = this.fb.array([this.createTimeSlot()]);
      this.openingHoursForm.addControl(day.name + '-times', timesArray);
    });
  }

  generateTimeSlots() {
    for (let hour = 0; hour < 24; hour++) {
      for (let min of [0, 30]) {
        this.timeSlots.push(
          `${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`
        );
      }
    }
  }

  getTimeControls(dayName: string): FormArray {
    return this.openingHoursForm.get(dayName + '-times') as FormArray;
  }

  createTimeSlot() {
    return this.fb.group({
      open: [''],
      close: ['']
    });
  }

  addTimeSlot(dayName: string) {
    const timesArray = this.getTimeControls(dayName);
    if (timesArray.length < 2) { // Maximal 2 Zeitintervalle erlauben
      timesArray.push(this.createTimeSlot());
    }
  }
  
  removeTimeSlot(dayName: string, index: number) {
    const timesArray = this.getTimeControls(dayName);
    if (timesArray.length > 1) { // Mindestens ein Intervall muss bleiben
      timesArray.removeAt(index);
    }
  }
  
  toggleClosed(dayName: string) {
    const closedControl = this.openingHoursForm.get(dayName + '-closed');
    if (closedControl?.value) {
      this.openingHoursForm.get(dayName + '-times')?.disable(); // Felder ausgrauen
    } else {
      this.openingHoursForm.get(dayName + '-times')?.enable(); // Felder aktivieren
    }
  }
  
  isClosed(dayName: string): boolean {
    return this.openingHoursForm.get(dayName + '-closed')?.value;
  }

  save() {
   const values = this.openingHoursForm.value;
   this.accountService.saveOpeningHours(this.data.account.idf, this.createRequest(values)).pipe(takeUntil(this.ngUnsubscribe$))
       .subscribe((data)=>{
         console.log(data);
         if(!data.errorCode) {
           this.success=true;
           this.error=false;
         } else {
           this.error = true;
           this.success = false;
         }

         setTimeout(()=>{
           this.dialogRef.close();
         },5000)
       })
  }

  private createRequest(values){
    let request = [];
    for(let day of this.weekDays) {
      let data = {dayOfWeek: day.name.toUpperCase(),
      open: !values[day.name + '-closed'], periods: values[day.name + '-times'] ? values[day.name +'-times'] : []}
      request.push(data);
    }
    return request;
  }
}

import {BrowserModule, Title} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {ROUTES} from './app.routes';
import {ViewModule} from './view/view.module';
import {StoreModule} from '@ngrx/store';
import {reducers, metaReducers} from './core/store/reducers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment, ENV_PROVIDERS} from '../environments/environment';
import {EffectsModule} from '@ngrx/effects';
import {UserEffects} from './core/store/effects/user.effects';
import {SubUserEffects} from './core/store/effects/sub-user.effects';
import {InvoicesEffects} from './core/store/effects/invoices.effects';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {APP_RESOLVER_PROVIDERS} from './app.resolver';
import {AuthGuardService} from './core/services/auth-guard.service';
import {AuthService} from './core/services/api/auth.service';
import {UserRestService} from './core/services/api/user.rest-service';
import {DatePipe} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip'; // RippleGlobalOptions, MAT_RIPPLE_GLOBAL_OPTIONS
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CUSTOM_DATE_FORMATS} from './core/models/date-format.model';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {SpecialOffersService} from './core/services/shop/special-offers.service';
import {CartService} from './core/services/cart.service';
import {CartProviderModule} from './modules/webshop/components/cart/cart-provider/cart-provider.module';
import {CalendarService} from './core/services/calendar.service';
import {InvoicesRestService} from './core/services/api/invoices.rest-service';
import {SpecialOffersEffects} from './core/store/effects/special-offers.effects';
import {ProFaxEffects} from './core/store/effects/pro-fax.effects';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {TotalsModule} from './modules/webshop/components/totals/totals.module';
import {PriceScaleModule} from './modules/webshop/components/price-scale/price-scale.module';
import {CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {MatPaginatorIntlDe} from '../assets/i18n/mat-paginator-intl.de';
import {NotificationModule} from './modules/shared/notification/notification.module';
import {RangeSelectModule} from './modules/account/components/invoices/invoice-search-form/date-range/range-select/range-select.module';
import {DateRangeModule} from './modules/account/components/invoices/invoice-search-form/date-range/date-range.module';
import {RangeToggleModule} from './modules/account/components/invoices/invoice-search-form/date-range/range-toggle/range-toggle.module';
import {LogService} from './core/services/utility/log.service';
import {ConsoleLogService} from './core/services/utility/console-log.service';
import {ViewPublicModule} from './view-public/view-public.module';
import {PharmaciesComponent} from './modules/account/pages/pharmacies/pharmacies.component';
import {TopPrismaService} from './core/services/shop/top-prisma.service';
import {TopPrismaEffects} from './core/store/effects/top-prisma.effects';
import {ExtraPrismaEffects} from './core/store/effects/extra-prisma.effects';
import {ExtraPrismaService} from './core/services/shop/extra-prisma.service';
import {UserPharmacyComponent} from './modules/services/pages/user-pharmacy/user-pharmacy.component';
import {MessageModule} from './modules/shared/message/message.module';
import {NotificationPublicModule} from './modules/shared/notification-public/notification-public.module';
import {RegistrationHeadingModule} from './modules/authentication/registration/registration-heading/registration-heading.module';
import {DokuLightEffects} from './core/store/effects/doku-light.effects';
import {DokuLightService} from './core/services/shop/doku-light.service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {WebshopServiceFactory} from './core/services/shop/webshop-service.factory';
import {ScrollingService} from './core/services/scrolling.service';
import {Paragraph73Effects} from './core/store/effects/paragraph73.effects';
import {Paragraph73Service} from './core/services/shop/paragraph73.service';
import {ExclusiveOffersEffects} from './core/store/effects/exclusive-offers.effects';
import {MessageEffects} from './core/store/effects/message.effects';
import {AccountService} from './core/services/api/account.service';
import {ProFaxService} from './core/services/shop/pro-fax.service';
import {AlertDialogComponent} from './modules/shared/alert-dialog/alert-dialog.component';
import {FirstLoginDialogComponent} from './modules/authentication/first-login-dialog/first-login-dialog.component';
import {IdfSelectionDialogComponent} from './modules/shared/idf-selection-dialog/idf-selection-dialog.component';
import {TrackTraceService} from './core/services/track-trace.service';
import {CategoryManagementSubRestService} from './core/services/api/categoryManagementSub.rest-service';
import {SurveyDialogComponent} from './modules/shared/survey-dialog/survey-dialog.component';
import {VaccinationService} from './core/services/vaccination.service';
import {JsonToPdfService} from './core/services/json-to-pdf.service';
import {PromoService} from './core/services/shop/promo.service';
import {UberService} from './core/services/shop/uber.service';
import {PromoEffects} from './core/store/effects/promo.effects';
import {UberEffects} from './core/store/effects/uber.effects';
import {FluShopService} from './core/services/flu-shop.service';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {SubsequentDeliveriesEffects} from './core/store/effects/subsequent.deliveries.effects';
import {ConfirmationDialogModule} from './modules/shared/confirmation-dialog/confirmation-dialog.module';
import {UberweiserOpenDialogModule} from './modules/shared/uberweiser-open-dialog/uberweiser-open-dialog.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {SafehtmlModule} from './modules/shared/safehtml/safehtml.module';
import {CmsModule} from './modules/shared/cms/cms.module';
import {TrackEffects} from './core/store/effects/track.effects';
import {RetourenService} from './core/services/retouren.service';
import {RetourenEffects} from './core/store/effects/retouren.effects';
import {DialogEffects} from './core/store/effects/dialog.effects';
import {FeedbackComponent} from './modules/shared/feedback/feedback.component';
import {TreuhandService} from './core/services/integrations/treuhand.service';
import {TreuhandEffects} from './core/store/effects/integrations/treuhand.effects';
import {NavService} from './core/services/nav.service';
import {RouteGuardService} from './core/services/routeGuards/route-guard.service';
import {ReklamationEffects} from './core/store/effects/reklamation.effects';
import {CommunicationRestService} from './core/services/api/communication.rest-service';
import {ProductSearchOpenDialogModule} from './modules/shared/product-search-open-dialog/product-search-open-dialog.module';
import {ScriptService} from './core/services/utility/scripts.service';
import {ChatbotService} from './core/services/chatbot.service';
import {ChatbotRestService} from './core/services/api/chatbot.rest-service';
import {ChatbotComponent} from './chatbot/chatbot.component';
import {RouteGuardPreorderService} from './core/services/routeGuards/route-guard-preorder.service';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);


// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    // ExclusiveOffersService,
    AccountService,
    AuthGuardService,
    AuthService,
    CalendarService,
    CartService,
    DokuLightService,
    ExtraPrismaService,
    InvoicesRestService,
    Paragraph73Service,
    ProFaxService,
    PromoService,
    UberService,
    ScrollingService,
    SpecialOffersService,
    TopPrismaService,
    UserRestService,
    WebshopServiceFactory,
    TrackTraceService,
    CategoryManagementSubRestService,
    VaccinationService,
    JsonToPdfService,
    FluShopService,
    ConfirmationDialogModule,
    UberweiserOpenDialogModule,
    ProductSearchOpenDialogModule,
    RetourenService,
    TreuhandService,
    NavService,
    RouteGuardService,
    TreuhandService,
    RouteGuardPreorderService,
    CommunicationRestService,
    ScriptService,
    ChatbotService,
    ChatbotRestService

];

/* Disables Ripple globally if provided
const globalRippleConfig: RippleGlobalOptions = {disabled: true};*/

@NgModule({
    declarations: [
        AlertDialogComponent,
        FirstLoginDialogComponent,
        AppComponent,
        PharmaciesComponent,
        UserPharmacyComponent,
        IdfSelectionDialogComponent,
        SurveyDialogComponent,
        FeedbackComponent,
        ChatbotComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CartProviderModule,
        FormsModule,
        HttpClientModule,
        MatButtonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatRippleModule,
        MatSelectModule,
        MatTooltipModule,
        MatProgressBarModule,
        MessageModule,
        NotificationModule,
        NotificationPublicModule,
        ReactiveFormsModule,
        ViewModule,
        ViewPublicModule,
        CmsModule,
        RouterModule.forRoot(ROUTES, {
            useHash: true,
            preloadingStrategy: PreloadAllModules,
            // enableTracing: true,
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
            relativeLinkResolution: 'legacy'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            }
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot(
            [
                UserEffects,
                SubUserEffects,
                InvoicesEffects,
                SpecialOffersEffects,
                TopPrismaEffects,
                ExtraPrismaEffects,
                DokuLightEffects,
                Paragraph73Effects,
                ExclusiveOffersEffects,
                ProFaxEffects,
                MessageEffects,
                PromoEffects,
                UberEffects,
                ReklamationEffects,
                SubsequentDeliveriesEffects,
                TrackEffects,
                RetourenEffects,
                DialogEffects,
                TreuhandEffects
            ]
        ),
        RangeSelectModule,
        RangeToggleModule,
        DateRangeModule,
        TotalsModule,
        PriceScaleModule,
        RegistrationHeadingModule,
        StoreRouterConnectingModule.forRoot(),
        SafehtmlModule
    ],
    providers: [
        ENV_PROVIDERS,
        APP_PROVIDERS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: 'de-DE'
        },
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorIntlDe
        },
        /*        {
                    provide: MAT_RIPPLE_GLOBAL_OPTIONS,
                    useValue: globalRippleConfig
                },*/
        DatePipe,
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
        {
            provide: LogService,
            useClass: ConsoleLogService
        },
        Title,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    exports: [
        SurveyDialogComponent,
        RouterModule
    ],
    entryComponents: [
        AlertDialogComponent,
        FirstLoginDialogComponent,
        IdfSelectionDialogComponent
    ]
})

export class AppModule {
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import {Routes, RouterModule} from '@angular/router';
import {ViewComponent} from './view.component';
import {CopyPasteElementsComponent} from '../copy-paste-elements/copy-paste-elements.component';
import {MySalesComponent} from '../modules/account/pages/my-sales/my-sales.component';
import {PharmaciesComponent} from '../modules/account/pages/pharmacies/pharmacies.component';
import {ProductType} from '../core/models/shop/product-type.model';
import {ProductFile} from '../core/models/shop/product-file.model';
import {ReklamationModule} from "../modules/account/pages/reklamation/reklamation.module";
import {ChatbotComponent} from '../chatbot/chatbot.component';
import {DownloadAreaComponent} from '../modules/webshop/components/download-area/download-area.component';



const routes: Routes = [
    {
        path: '', component: ViewComponent, children: [
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {path: 'promotions', redirectTo: 'shop', pathMatch: 'full'},
            {path: 'DOKULIGHT', redirectTo: 'shop', pathMatch: 'full'},
            {
                path: 'dashboard',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'belegsuche',
                loadChildren: () => import('../modules/account/components/invoices/invoices.module').then(m => m.InvoicesModule)
            },
            {path: 'mein-umsatz', component: MySalesComponent},
            {

                path: 'payback',
                loadChildren: () => import('../modules/payback/payback.module').then(m => m.PaybackModule)
            },
            {

                path: 'auswertungen',
                loadChildren: () => import('../modules/account/pages/evaluations/evaluations.module').then(m => m.EvaluationsModule)
            },
            {
                path: 'reklamation',
                loadChildren: () => import('../modules/account/pages/reklamation/reklamation.module').then(m => m.ReklamationModule)
            },
            {

                path: 'apothekenstammdaten',

                loadChildren: () => import('../modules/account/components/account-data/account-data.module').then(m => m.AccountDataModule)
            },
            {path: 'copy-paste-elements', component: CopyPasteElementsComponent},

            {
                path: 'bestellung',
                loadChildren: () => import('../modules/webshop/components/order/order.module').then(m => m.OrderModule)
            },

            // top prisma

            {

                path: 'TOPPRISMA',
                component: DownloadAreaComponent,

                data: {

                    downloadArea: [{
                        heading: 'WEBSHOP.TOPPRISMA.DOWNLOADS.HEADING',
                        text: 'WEBSHOP.TOPPRISMA.DOWNLOADS.TEXT',
                        links: [
                            {
                                file: ProductFile.TopPrisma,
                                analytics: 'Angebote',
                                text: 'WEBSHOP.TOPPRISMA.DOWNLOADS.LINK'
                            }
                        ]
                    },
                        {
                            heading: 'WEBSHOP.BASISLISTE.DOWNLOADS.HEADING',
                            text: 'WEBSHOP.BASISLISTE.DOWNLOADS.TEXT',
                            authorities: ['BASISLISTE'],
                            links: [
                                {
                                    file: ProductFile.TopPrisma,
                                    analytics: 'Basisliste',
                                    text: 'WEBSHOP.BASISLISTE.DOWNLOADS.LINK'
                                }
                            ]
                        }
                    ]
                }


            },

            // extra prisma
            {
                path: ProductType.ExtraPrisma,
                loadChildren: () => import('../modules/webshop/webshop-loader/webshop-loader.module').then(m => m.WebshopLoaderModule),
                data: {
                    productType: ProductType.ExtraPrisma,
                    totalsInformation: 'WEBSHOP.EXTRAPRISMA.CURRENT_OFFERS.TOTALS',
                }
            },

            // doku light
            {
                path: 'shop',
                loadChildren: () => import('../modules/webshop/webshop-loader/webshop-loader.module').then(m => m.WebshopLoaderModule),
                data: {
                    productType: ProductType.DokuLight,
                    heading: {
                        id: 'WEBSHOP.DOKU_LIGHT.SEARCH.HEADING'
                    },
                    activeSearchBox: {
                        availableOnlyCheckboxEnabled: true,
                        label: 'WEBSHOP.DOKU_LIGHT.SEARCH.LABEL',
                        desc: 'WEBSHOP.DOKU_LIGHT.SEARCH.DESC'

                    },
                    activePromoView: true
                }
            },

            // profax
            {
                path: ProductType.Profax,
                loadChildren: () => import('../modules/webshop/webshop-loader/webshop-loader.module').then(m => m.WebshopLoaderModule),
                data: {
                    productType: ProductType.Profax,
                }
            },

            // promotions
            // {
            //   path: 'promotions',
            //   loadChildren: () => import('../modules/webshop/webshop-loader/webshop-loader.module').then(m => m.WebshopLoaderModule),
            //   data: {
            //     productType: ProductType.Promo,
            //   }
            // },

            // paragraph 73
            {
                path: ProductType.Paragraph73,
                loadChildren: () => import('../modules/webshop/webshop-loader/webshop-loader.module').then(m => m.WebshopLoaderModule),
                data: {
                    productType: ProductType.Paragraph73,
                    heading: {
                        id: 'WEBSHOP.PARAGRAPH73.SEARCH.HEADING',
                    },
                    activeSearchBox: {
                        availableOnlyCheckboxEnabled: false,
                        label: 'WEBSHOP.PARAGRAPH73.SEARCH.LABEL',
                        desc: 'WEBSHOP.PARAGRAPH73.SEARCH.DESC'
                    },
                }
            },

            // privatsortiment
            {
                path: ProductType.PrivateStock,
                loadChildren: () => import('../modules/webshop/webshop-loader/webshop-loader.module').then(m => m.WebshopLoaderModule),
                data: {
                    productType: ProductType.PrivateStock,
                    activeSearchBox: true,
                }
            },

            // product details
            {
                path: 'product-details',
                loadChildren: () => import('../modules/webshop/components/product-details/product-details.module').then(m => m.ProductDetailsModule)
            },
            {
                path: 'static-content',
                loadChildren: () => import('../modules/static-content/static-content.module').then(m => m.StaticContentModule),
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'mitbenutzerverwaltung',
                loadChildren: () => import('../modules/account/components/sub-user-management/sub-user-management.module').then(m => m.SubUserManagementModule)
            },

            {
                path: 'benachrichtigungen',
                loadChildren: () => import('../modules/account/components/communication/communication.module').then(m => m.CommunicationModule)
            },
            {path: 'meine-apotheken', component: PharmaciesComponent},
            {
                path: 'kontaktformular',
                loadChildren: () => import('../modules/account/pages/contact-form/contact-form.module').then(m => m.ContactFormModule)
            },
            {
                path: 'fragenundantworten',
                loadChildren: () => import('../modules/account/pages/supports/supports.module').then(m => m.SupportsModule)
            },
            {
                path: 'static-category',
                loadChildren: () => import('../modules/account/pages/supports/support-category/support-category.module').then(m => m.SupportCategoryModule),
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'warenkorb',
                loadChildren: () => import('../modules/webshop/components/cart/cart.module').then(m => m.CartModule)
            },
            {
                path: 'track-trace',
                loadChildren: () => import('../modules/webshop/components/track-trace/track-trace.module').then(m => m.TrackTraceModule)
            },
            {
                path: 'vormerkungen',
                loadChildren: () => import('../modules/webshop/components/vaccination/vaccination.module').then(m => m.VaccinationModule)
            },
            {
                path: 'zuteilungsmenge',
                loadChildren: () => import('../modules/webshop/components/vaccine-reservations/vaccine-reservations.module').then(m => m.VaccineReservationsModule)
            },
            {
                path: 'vorbestellung/:categoryUrl',
                loadChildren: () => import('../modules/webshop/components/flu-shop/flu-shop.module').then(m => m.FluShopModule)
            },
            {
                path: 'vorbestellung/archive/:categoryUrl',
                loadChildren: () => import('../modules/webshop/components/flu-shop/pre-order-archive/pre-order-archive.module').then(m => m.PreOrderArchiveModule)
            },
            {
                path: 'postfach',
                loadChildren: () => import('../modules/account/components/message-list/message-list.module').then(m => m.MessageListModule)
            },
            {
                path: 'schnittstellen',
                loadChildren: () => import('../modules/account/components/integrations/integrations.module').then(m => m.IntegrationsModule)
            },
            {
                path: 'nachlieferung',
                loadChildren: () => import('../modules/webshop/components/subsequent-deliveries/subsequent-deliveries.module').then(m => m.SubsequentDeliveriesModule)
            },
            {
                path: 'feiertagen',
                loadChildren: () => import('../modules/webshop/components/holiday-deliveries/holiday-deliveries.module').then(m => m.HolidayDeliveriesModule)
            },
            {



                path: 'uberweiser',

                loadChildren: () => import('../modules/webshop/components/uberweiser/uberweiser.module').then(m => m.UberweiserModule)
            },
            {
                path: 'pkp',
                loadChildren: () => import('./linda/linda.module').then(m => m.LindaModule)
            },
            {
                path: 'page/:url',

                loadChildren: () => import('../modules/shared/cms/cms.module').then(m => m.CmsModule),
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'advertorial/:link',
                loadChildren: () => import('../modules/public/advertorial/advertorial.module').then(m => m.AdvertorialModule),
                data: {
                    heading: {
                        id: 'NAVIGATION.ORDER.ADVERTORIAL.HEADING'
                    }
                }
            },
            {

                path: 'lageraktion/:categoryUrl',
                    loadChildren: () => import('../modules/webshop/components/flu-shop/lageraktion/lageraktion.module').then(m => m.LageraktionModule)
            },
            {
                path: 'retouren',
                loadChildren: () => import('../modules/webshop/components/retouren/retouren.module').then(m => m.RetourenModule)
            },
            {
                path: 'contest/:url',
                loadChildren: () => import('../modules/shared/cms/cms.module').then(m => m.CmsModule),
                runGuardsAndResolvers: 'always'
            },
            {
        path: 'invoices/heute',
        loadChildren: () => import('../modules/account/components/invoices/invoice-new/invoice-new.module').then(m => m.InvoiceNewModule)
      },

            {
        path: 'invoices/heute-neueste',
        loadChildren: () => import('../modules/account/components/invoices/invoice-new/invoice-new.module').then(m => m.InvoiceNewModule)
      },
            // {
            //     path: 'carpe-sortimentum',
            //     loadChildren: () => import('../modules/webshop/components/assortment-challenge/assortment-challenge.module').then(m => m.AssortmentChallengeModule)
            // }


        ]
    }
];
export const ROUTES = RouterModule.forChild(routes);

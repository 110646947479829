import {Component, Inject, Input, OnInit} from '@angular/core';
import {AdsService} from '../../core/services/ads.service';
import {MatomoService} from '../../core/services/matomo.service';

import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AdsRestService} from '../../core/services/api/cms/ads.rest-service';
import {UserService} from '../../core/services/user.service';


@Component({
    selector: 'app-ads',
    templateUrl: './ads.component.html',
    styleUrls: ['./ads.component.scss']
})
export class AdsComponent implements OnInit {
    public ads = [];
    private ngUnsubscribe$ = new Subject<any>();
    private selected = null;
    @Input() public position: string;


    constructor(@Inject(AdsService) private adService: AdsService,
                private adsRestService: AdsRestService,
                private matomoService: MatomoService,

                @Inject(UserService) private userService: UserService,

                @Inject('CDN') private CDN) {
    }

    ngOnInit(): void {
        if (this.ads.length < 1) {
            this.adsRestService.getCurrentAds(this.position)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe(ads => {
                    if (this.userService.isAdmin()) {
                        this.ads = this.adService.showAd(ads.return_object, true);
                    } else {
                        this.ads = this.adService.showAd(ads.return_object);
                    }

                    if (this.selected !== this.ads[0]) {
                        this.selected = this.ads[0];
                        if(this.selected){
                            this.matomoService.trackEvent('Ad Shown', this.selected.title);
                        }
                    }
                });
        }
    }

    mtmEvent(action) {
        this.matomoService.trackEvent('Klick auf Ads Banner', action);
    }
}

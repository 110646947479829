import {NavItem, NavType} from './nav-item.model';
import {ProductType} from "./shop/product-type.model";
import {TileStatus} from './enums/tile-status.model';

export class DashboardModel {
    headline = '';
    component = '';
    tree: NavItem[];

    constructor(obj: any = {},
                cmsHost: string = '',
                vaccineConfig: any = {},
                fluShopTiles: any = {},
                CDN: string = '') {
        this.headline = '';
        this.component = 'dashboard';
        this.tree = [];

        const fluShopTree = [];
        if (fluShopTiles && fluShopTiles.length > 0) {
            fluShopTiles.forEach(item => {
                fluShopTree.push(new NavItem({
                    navLinkName: item.tile.text,
                    heading: item.headerText,
                    path: `vorbestellung/${item.url}`,
                    tileTitle: item.tile.text,
                    tileImageFileName: item.tile.image ? `${CDN}/marketing-management/${item.tile.image}` : 'assets/img/thumb.png',
                    type: NavType.VACCINE,
                    tilePosition: item.tile.position,
                    isTile: true,
                    class: item.tile.htmlClass,
                    pageStatus: this.getPageStatus(item.tile.status, this.parseDate(item.tile.statusValidUntil)),
                    allowedDetails: ['orders']
                }));
            });
        }

        this.tree.push(new NavItem({
            navLinkName: 'NAVIGATION.QUICK.ACCESS.LINK',
            showInNav: false,
            allowedDetails: [
                'sales',
                'orders',
                'invoices',
                'nachlieferung',
                'evaluations'
            ],
            tree: [
                new NavItem({
                    navLinkName: 'NAVIGATION.ORDER.PRODUCTS.LINK',
                    heading: 'NAVIGATION.ORDER.PRODUCTS.HEADING',
                    component: 'doku-light',
                    activatesPath: ['doku-light-details'],
                    path: 'shop',
                    isTile: true,
                    tileTitle: 'NAVIGATION.ORDER.PRODUCTS.TILE',
                    tileImageFileName: 'assets/img/icons/tiles/shop.svg',
                    dataCy: 'doku-light',
                    requiredAuthorities: ['ORDERS'],
                    allowedDetails: ['orders'],
                    tilePosition: 10
                }),

                new NavItem({
                    navLinkName: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.LINK',
                    heading: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.HEADING',
                    component: 'nachlieferung',
                    path: 'nachlieferung',
                    requiredAuthorities: ['NACH'],
                    isTile: true,
                    tileTitle: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.TILE',
                    tileImageFileName: 'assets/img/icons/tiles/nachlieferung.svg',
                    allowedDetails: ['nachlieferung'],
                    tilePosition: 20,
                    pageStatus: this.getPageStatus(TileStatus.UPDATE, this.parseDate('2023-10-18T18:00:00'))
                }),
                new NavItem({
                    navLinkName: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.LINK',
                    heading: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.HEADING',
                    component: 'nachlieferung',
                    path: 'nachlieferung',
                    isTile: true,
                    tileTitle: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.TILE',
                    tileImageFileName: 'assets/img/icons/tiles/nachlieferung.svg',
                    excludedAuthorities: ['E_SARE'],
                    excludedDetails: ['esare'],
                    tilePosition: 20,
                    type: NavType.DISABLED,
                    showInNav: false,
                    class: 'tile--inactive'
                }),
                new NavItem({
                    navLinkName: 'NAVIGATION.ORDER.RETOUREN.LINK',
                    heading: 'NAVIGATION.ORDER.RETOUREN.HEADING',
                    component: 'retouren',
                    path: 'retouren',
                    isTile: true,
                    allowedDetails: ['returns'],
                    tileTitle: 'NAVIGATION.ORDER.RETOUREN.TILE',
                    tileImageFileName: 'assets/img/icons/tiles/retouren.svg',
                    tilePosition: 30,
                    requiredAuthorities: ['PRODUCT_RETURNS'],
                }),
                new NavItem({
                    navLinkName: 'NAVIGATION.DATA.EVALUATION.LINK',
                    isTile: true,
                    tileTitle: 'NAVIGATION.DATA.EVALUATION.TILE',
                    heading: 'NAVIGATION.DATA.EVALUATION.HEADING',
                    component: 'evaluations',
                    path: 'auswertungen',
                    tileImageFileName: 'assets/img/icons/tiles/auswertungen.svg',
                    allowedDetails: ['evaluations'],
                    requiredAuthorities: ['SLIMFIT_EVALUATIONS'],
                    tilePosition: 40
                }),
                new NavItem({
                    navLinkName: 'NAVIGATION.DATA.REKLAMATION.LINK',
                    isTile: true,
                    tileTitle: 'NAVIGATION.DATA.REKLAMATION.TILE',
                    heading: 'NAVIGATION.DATA.REKLAMATION.HEADING',
                    component: 'reklamation',
                    path: 'reklamation',
                    tileImageFileName: 'assets/img/icons/tiles/reklamation.svg',
                    tilePosition: 50
                }),
                new NavItem({
                    navLinkName: 'NAVIGATION.SERVICE.LINDA.LINK',
                    heading: 'NAVIGATION.SERVICE.LINDA.HEADING',
                    component: 'linda',
                    path: 'pkp',
                    activatesPath: ['pkp'],
                    isTile: true,
                    tileTitle: 'NAVIGATION.SERVICE.LINDA.TILE',
                    tileImageFileName: 'assets/img/tiles/svg/LINDA-kachel.svg',
                    tileImageFileNameHover: 'assets/img/tiles/svg/LINDA-kachel.svg',
                    shouldOpenInNewTab: false,
                    tilePosition: 60
                }),
                new NavItem({
                    navLinkName: 'NAVIGATION.DATA.PAYBACK.LINK',
                    isTile: true,
                    tileTitle: 'NAVIGATION.DATA.PAYBACK.TILE',
                    heading: 'NAVIGATION.DATA.PAYBACK.HEADING',
                    component: 'payback',
                    path: 'payback',
                    tileImageFileName: 'assets/img/tiles/svg/payback-new-kachel.svg',
                    tileImageFileNameHover: 'assets/img/tiles/svg/payback-new-hover-kachel.svg',
                    tilePosition: 70
                }),
                new NavItem({
                    navLinkName: 'NAVIGATION.ORDER.VORMERKUNGEN.LINK',
                    component: 'vormerkungen',
                    path: ProductType.Vormerkungen,
                    isTile: true,
                    tileTitle: 'NAVIGATION.ORDER.VORMERKUNGEN.TILE',
                    tileImageFileName: 'assets/img/icons/tiles/syringe.svg',
                    requiredAuthorities: ['ORDERS'],
                    type: NavType.VACCINE,
                    tilePosition: 80,
                    start: vaccineConfig.activeFrom,
                    end: vaccineConfig.activeUntil,
                    allowedDetails: ['orders']
                }),
                new NavItem({
                    navLinkName: 'NAVIGATION.PREFERENCES.COMMUNICATIONS.LINK',
                    component: 'benachrichtigungen',
                    path: 'benachrichtigungen',
                    requiredAuthorities: ['GROUP_ACCOUNT_OWNER'],
                    isTile: true,
                    tileTitle: 'NAVIGATION.PREFERENCES.COMMUNICATIONS.TILE',
                    tileImageFileName: 'assets/img/icons/tiles/notifications.svg',
                    tilePosition: 90,
                }),
                new NavItem({
                    navLinkName: 'NAVIGATION.ORDER.FLUSHOP.LINK',
                    component: 'flu-shop',
                    path: 'vorbestellung/grippe2025',
                    requiredAuthorities: ['ORDERS'],
                    isTile: true,
                    tileTitle: 'NAVIGATION.ORDER.FLUSHOP.TILE',
                    tileImageFileName: 'assets/img/icons/tiles/flu-shop.svg',
                    tilePosition: 100,
                    pageStatus: this.getPageStatus(TileStatus.NEW, this.parseDate('2025-02-01T18:00:00'))
                }),
  ...fluShopTree
            ]
        }));


        this.tree.push(new NavItem({
            navLinkName: 'NAVIGATION.EXCLUSIVE.OFFERS.LINK',
            // heading: 'NAVIGATION.CHATBOT.HEADING',
            showInNav: false,
            requiredAuthorities: [
                'GROUP_ADMIN',
                'GROUP_ACCOUNT_OWNER',
                'GROUP_SUBUSER',
                'ORDERS'],
            tree: [
                new NavItem({
                    isTile: true,
                    type: NavType.EXCLUSIVEOFFERS,
                })
            ],
            allowedDetails: ['orders']
        }));

        this.tree.push(new NavItem({
            navLinkName: 'NAVIGATION.ORDER.ADVERTORIAL.LINK',
            showInNav: false,
            // start: '2025-02-16T00:01:00',
            end: '2025-02-28T23:59:00',
            tree: [
                new NavItem({
                    isTile: true,
                    type: NavType.ADVERTORIAL,
                })
            ]
        }));

        const serviceTree = [];

        serviceTree.push(
            new NavItem({
                type: NavType.PDF,
                navLinkName: 'NAVIGATION.SERVICE.PDF_SOLERO.LINK',
                path: 'https://cspappsacc.blob.core.windows.net/cms/files/2025/solero/SOLERO Fact-sheet inkl. Verlinkung.pdf',
                isTile: true,
                tileTitle: 'NAVIGATION.SERVICE.PDF_SOLERO.TILE',
                tileImageFileName: 'assets/img/tiles/solero_logo_web Apothekenportal.jpg',
                tileImageFileNameHover: 'assets/img/tiles/solero_logo_web Apothekenportal.jpg',
                shouldOpenInNewTab: true,
                pageStatus: this.getPageStatus(TileStatus.NEW, this.parseDate('2024-12-12T18:00:00')),
                start: '2025-02-03T01:00:00',
                end: '2025-02-28T22:00:00',
                tilePosition: 2
            }),
            new NavItem({
                type: NavType.PDF,
                navLinkName: 'NAVIGATION.SERVICE.PDF.LINK',
                path: 'https://cspappsacc.blob.core.windows.net/cms/files/2024/PHOENIX Marketing Jahresplan 2024.pdf',
                isTile: true,
                tileTitle: 'NAVIGATION.SERVICE.PDF.TILE',
                tileImageFileName: 'assets/img/tiles/svg/marketing-calendar-kachel.svg',
                tileImageFileNameHover: 'assets/img/tiles/svg/marketing-calendar-kachel.svg',
                shouldOpenInNewTab: true,
                pageStatus: this.getPageStatus(TileStatus.NEW, this.parseDate('2024-01-16T18:00:00')),
                tilePosition: 4
            }),
            new NavItem({
                // requiredAuthorities: ['PAYBACK', 'GROUP_ADMIN', 'GROUP_INTERNAL_USER'],
                navLinkName: 'NAVIGATION.SERVICE.DEINEAPOTHEKESTORE.LINK',
                isTile: true,
                tileTitle: 'NAVIGATION.SERVICE.DEINEAPOTHEKESTORE.TILE',
                tileImageFileName: 'assets/img/tiles/svg/phoenixstore-kachel.svg',
                tileImageFileNameHover: 'assets/img/tiles/svg/phoenixstore-hover-kachel.svg',
                shouldOpenInNewTab: true,
                type: NavType.DEINEAPOTHEKESTORE,
                tilePosition: 20,
            }),

            new NavItem({
                requiredAuthorities: ['GROUP_ACCOUNT_OWNER', 'GROUP_ADMIN', 'GROUP_INTERNAL_USER'],
                navLinkName: 'NAVIGATION.SERVICE.PRIVATSORTIMENT.LINK',
                isTile: true,
                tileTitle: 'NAVIGATION.SERVICE.PRIVATSORTIMENT.TILE',
                tileImageFileName: 'assets/img/tiles/svg/genuss-kultur-kachel.svg',
                tileImageFileNameHover: 'assets/img/tiles/svg/genuss-kultur-hover-kachel.svg',
                shouldOpenInNewTab: true,
                type: NavType.PRIVATSORTIMENT,
                tilePosition: 30
            })

        );

        if (obj && obj.length > 0) {
            obj.forEach((element) => {
                serviceTree.push(new NavItem({
                    navLinkName: element.title,
                    heading: element.title,
                    extUrl: element.url,
                    path: 'static-content',
                    isTile: true,
                    tileTitle: element.title,
                    tileImageFileName: cmsHost + element['button-icon-normal'],
                    tileImageFileNameHover: cmsHost + element['button-icon-hover'],
                    pageStatus: this.getPageStatus(element['page-status'], element['page-status-until']),
                    tilePosition: 5,
                    class: (element.url).slice(1)
                }));
            });
        }

        this.tree.push(new NavItem({
            navLinkName: 'NAVIGATION.SERVICE.LINK',
            tree: serviceTree
        }));
    }


    getDetailsForRoute(route){
        if (!this.tree || route === '/dashboard') {
            return [];
        }
        let details: string[] = [];
        this.tree.forEach(navItem => {
            if (route.indexOf(navItem.path) !== -1) {
                details = navItem.hasOwnProperty('allowedDetails') ? navItem['allowedDetails'] : [];
            } else if (navItem.tree) {
                navItem.tree.forEach(subNavItem => {
                    if (route.indexOf(subNavItem.path) !== -1) {
                        details = subNavItem.hasOwnProperty('allowedDetails') ? subNavItem['allowedDetails'] : [];
                    }
                });
            }
        });
        return details;
    }

    getAuthoritiesForRoute(route: string): string[] {
        if (!this.tree || route === '/dashboard') {
            return [];
        }
        let authorities: string[] = [];
        this.tree.forEach(navItem => {
            if (route.indexOf(navItem.path) !== -1) {
                authorities = navItem.hasOwnProperty('requiredAuthorities') ? navItem['requiredAuthorities'] : [];
            } else if (navItem.tree) {
                navItem.tree.forEach(subNavItem => {
                    if (route.indexOf(subNavItem.path) !== -1) {
                        authorities = subNavItem.hasOwnProperty('requiredAuthorities') ? subNavItem['requiredAuthorities'] : [];
                    }
                });
            }
        });
        return authorities;
    }

    getFeatureForRoute(route: string): string {
        if (!this.tree || route === '/dashboard') {
            return '';
        }

        let feature = '';

        this.tree.forEach(navItem => {
            if (route.indexOf(navItem.path) !== -1) {
                feature = navItem.feature ? navItem.feature : '';
            } else if (navItem.tree) {
                navItem.tree.forEach(subNavItem => {
                    if (route.indexOf(subNavItem.path) !== -1) {
                        feature = subNavItem.feature ? subNavItem.feature : '';
                    }
                });
            }
        });

        return feature;
    }

    parseDate(string) {
        const time = new Date(string);
        return time.getTime() / 1000;
    }

    getVisibleForDcArray(route: string): number[] {
        if (!this.tree || route === '/dashboard') {
            return [];
        }
        let visibility = [];

        this.tree.forEach(navItem => {
            if (route.indexOf(navItem.path) !== -1) {
                visibility = navItem.visibleForDc ? navItem.visibleForDc : [];
            } else if (navItem.tree) {
                navItem.tree.forEach(subNavItem => {
                    if (route.indexOf(subNavItem.path) !== -1) {
                        visibility = subNavItem.visibleForDc ? subNavItem.visibleForDc : [];
                    }
                });
            }
        });

        return visibility;
    }

    getPageStatus(pageStatus: TileStatus, until) {
        const now = new Date();

        if (until < now.getTime() / 1000) {
            return 0;
        }
        return TileStatus[pageStatus];
    }
}

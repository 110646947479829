<h1 mat-dialog-title class="text-center">{{data.heading}}</h1>
<div mat-dialog-content>
    <p class="idf-selection-dialog-message">{{data.message}} (<span class="idf-selection-dialog-username"><i
            class="fa fa-user" aria-hidden="true"></i>{{data.userName}}</span>):</p>
    <div class="d-flex align-items-center">
        <img class="idf-selection-dialog-image" src="{{this.data.imageFileName}}" alt="Haus ikon">
        <div class="idf-selection-dialog-button-container">
            <ng-container *ngIf="this.userService.getAuthTokensObservable() | async as authTokens">
                <ng-container *ngFor="let authToken of authTokens | keyvalue">
                    <ng-container [ngSwitch]="data.navType">
                        <ng-container *ngSwitchCase="NavType.NOTFALLDEPOT">
                            <a href="{{this.ND_URL}}?user={{data.userName}}&token={{authToken.value}}&idf={{authToken.key}}&referrer={{this.ND_REFERRER}}&{{this.ND_FLAG}}=1"
                               target="_blank">
                                <button class="btn-secondary" style="border: none;" mat-flat-button
                                        [mat-dialog-close]="true">
                                    {{authToken.key}}</button>
                            </a>
                        </ng-container>
                        <ng-container *ngSwitchCase="NavType.DEINEAPOTHEKESTORE">
                            <a href="{{this.DA_STORE_URL}}?user={{data.userName}}&token={{authToken.value}}&idf={{authToken.key}}&referrer={{this.DA_STORE_REFERRER}}"
                               target="_blank" class="linki">{{authToken.key}}&nbsp;{{getLabel(authToken.key)}}
                                <!--                            <button class="btn-secondary" style="border: none;" mat-flat-button-->
                                <!--                                    [mat-dialog-close]="true">{{authToken.key}}</button>-->
                            </a>
                        </ng-container>
                        <ng-container *ngSwitchCase="NavType.LINDA">
                            <a (click)="goToLinda(authToken.key)" target="_blank">{{getLabel(authToken.key)}}
                                <button class="btn-secondary" style="border: none;" mat-flat-button>
                                    {{authToken.key}}</button>
                            </a>
                        </ng-container>
                    <ng-container *ngSwitchCase="NavType.INVOICE">
                        <a (click)="quickSearch(authToken.key)" >{{getLabel(authToken.key)}}
                            <button class="btn-secondary" style="border: none;" mat-flat-button [mat-dialog-close]="true">
                                {{authToken.key}}</button>
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="NavType.LINDA_ADMIN">

                            <a (click)="goToLinda(3381793)" target="_blank">LFL3 Linda | Apotheke Jürgen Lutsch e.K.
                                <button class="btn-secondary" style="border: none;" mat-flat-button>
                                    3381793
                                </button>
                            </a>
                            <a (click)="goToLinda(3022812)" target="_blank">LFL2 MVDA contract | Rats-Apotheke
                                <button class="btn-secondary" style="border: none;" mat-flat-button>
                                    3022812
                                </button>
                            </a>
                            <a (click)="goToLinda(3030183)" target="_blank">LFL2 without MVDA | contract Haslach Apotheke
                                <button class="btn-secondary" style="border: none;" mat-flat-button>
                                    3030183
                                </button>
                            </a>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button class="btn-primary float-right" mat-button [mat-dialog-close]="true" cdkFocusInitial>Schlie&szlig;en
    </button>
</div>

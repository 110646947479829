import {Component, Inject, OnInit} from '@angular/core';
import {CartService} from '../../core/services/cart.service';
import {Totals} from '../../core/models/totals.model';
import {Observable} from 'rxjs';
import {CartItem} from '../../core/models/shop/cart-item.model';
import {Router} from '@angular/router';
import {MessageResponse} from '../../core/models/message/message-response.model';
import {MessageService} from '../../core/services/message.service';
import {UserService} from '../../core/services/user.service';
import {Store} from '@ngrx/store';
import * as fromReducers from '../../core/store/reducers';
import {InitMessagesAction} from '../../core/store/actions/message.actions';
import {MatomoService} from '../../core/services/matomo.service';
import {NavType} from '../../core/models/nav-item.model';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-quick-nav',
    templateUrl: './quick-nav.component.html',
    styleUrls: ['./quick-nav.component.scss']
})
export class QuickNavComponent implements OnInit {
    public totals$: Observable<Totals> = null;
    public cartItems$: Observable<CartItem[]> = null;
    public latestItems$: Observable<CartItem[]> = null;
    public mails$: Observable<MessageResponse> = null;

    constructor(@Inject(CartService) cartService: CartService,
                @Inject(MessageService) public messageService: MessageService,
                @Inject(UserService) public userService: UserService,
                private router: Router,
                public store: Store<fromReducers.State>,
                private translate: TranslateService,
                public matomoService: MatomoService) {
        this.totals$ = cartService.getTotalsObservable();
        this.cartItems$ = cartService.getCartItemsObservable();
        this.latestItems$ = cartService.getLatestCartItemsObservable();
        // if (this.canMail()) {
        //     this.store.dispatch(new InitMessagesAction());
        //     this.mails$ = messageService.getMessagesObservable();
        // }
    }

    ngOnInit() {
    }




    // canMail(): boolean {
    //     return !this.userService.hasRequiredAuthority(['GROUP_INTERNAL_USER'])
    //         && !this.userService.hasRequiredAuthority(['GROUP_ADMIN'])
    //         && this.userService.hasRequiredAuthority(['MAILBOX']);
    // }

    canOrders(): boolean {
        return this.userService.hasRequiredAuthority(['ORDERS'])
            // || this.userService.hasRequiredAuthority(['PAYBACK']);
    }

    trackingEvent(name) {
        this.matomoService.trackEvent('FEATUREBEZOGENE KPI', 'Klick auf Quick Navigation', name);
    }

    trackClick(linkeName) {
        const name = this.translate.instant(linkeName);
        this.trackingEvent(linkeName);
    }

    protected readonly NavType = NavType;

    isOwner() {
        return this.userService.isOwner();
    }

    protected readonly sessionStorage = sessionStorage;
}

import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FluShopRestService} from './api/fluShop-rest-service';
import {PreOrderCategoryActive} from '../models/shop/pre-order-category-active.model';
import {PreOrderCategoryDetails, PreOrderSupplierModel} from '../models/shop/pre-order-supplier.model';
import {PreOrderResponseModel} from '../models/shop/pre-order-response.model';
import {PreOrderArchivedResponseModel} from '../models/shop/pre-order-archived-response.model';
import {PreOrderArchivedItemResponseModel} from '../models/shop/pre-order-archived-item-response.model';

@Injectable({
    providedIn: 'root',
})
export class FluShopService implements OnDestroy {
    public activePreOrderCategories$ = new Subject<PreOrderCategoryActive[]>();
    public preOrderCategoryDetails$ = new Subject<PreOrderCategoryDetails>();
    public preOrders$ = new Subject<PreOrderResponseModel>();
    public archivedPreOrders$ = new Subject<{[key: string]: PreOrderArchivedResponseModel}>();
    public archivedPreOrderItems$ = new Subject<PreOrderArchivedItemResponseModel[]>();
    private ngUnsubscribe$ = new Subject<any>();
    public activeCategories;

    constructor(
        @Inject(FluShopRestService) public restService: FluShopRestService
    ) {
    }

    loadActivePreorderCategories() {
        this.restService
            .getActivePreorderCategories()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((response) => {
                if (response.returnObject) {
                    this.activeCategories = response.returnObject;
                    return this.activePreOrderCategories$.next(response.returnObject);
                }
                return this.activePreOrderCategories$;
            });
        return this.activePreOrderCategories$.asObservable();
    }

    getActiveCategories() {
        return this.activeCategories;
    }

    loadPreorderCategoryDetailsByUrl(categoryUrl: string) {
        this.preOrderCategoryDetails$ = new Subject<PreOrderCategoryDetails>();
        this.restService
            .getPreorderSuppliersByUrl(categoryUrl)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((response: any) => {
                if (response) {
                    const data = response.returnObject;
                    const categoryDetails: PreOrderCategoryDetails = {
                        name: data.name,
                        headerText: data.headerText,
                        archiveDisplayName: data.archiveDisplayName,
                        description: data.description,
                        config: data.config,
                        vendors: data.vendors.map((vendor: any) => new PreOrderSupplierModel(vendor))
                    };
                    this.preOrderCategoryDetails$.next(categoryDetails);
                }
            });
        return this.preOrderCategoryDetails$.asObservable();
    }

    loadPreordersForIdf(period: number, idf: string) {
        this.restService
            .getPreordersForIdf(period, idf)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((response) => {
                if (response.returnObject) {
                    return this.preOrders$.next(response.returnObject);
                }
                return this.preOrders$;
            });
        return this.preOrders$.asObservable();
    }

    updatePreorderRequest(period: number, idf: string, data: any) {
        return this.restService.saveOrdersForPeriod(period, idf, data);
    }

    loadArchivedPreOrders(idf: string) {
        this.restService
            .getArchivesForIdf(idf)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((response) => {
                if (response.returnObject) {
                    return this.archivedPreOrders$.next(response.returnObject);
                }
                return this.archivedPreOrders$;
            });
        return this.archivedPreOrders$.asObservable();
    }

    loadSingleArchivePreOrderItems(idf: any, id: any) {
        this.archivedPreOrderItems$ = new Subject<PreOrderArchivedItemResponseModel[]>();
        this.restService.getArchivePreOrderById(idf, id).subscribe((response) => {
            if (response.returnObject) {
                return this.archivedPreOrderItems$.next(response.returnObject);
            }
            return this.archivedPreOrderItems$;
        });
        return this.archivedPreOrderItems$.asObservable();
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}

<div class="d-flex justify-content-center">
  <nav class="navbar fixed-top navbar-expand-xl navbar-dark navbar-phoenix py-0">
    <a class="navbar-brand position-relative px-xxl mr-xxs"
       href="#/dashboard" (click)="mtmEvent('Phoenix logo')"
       matTooltip="Zum Dashboard">
      <img src="assets/img/phoenix-logo_white.svg" width="50" alt="">
      <pre class="nav-environment--qa" *ngIf="isQa()">&nbsp;QA</pre>
      <pre class="nav-environment--dev" *ngIf="isDev()">&nbsp;DEV</pre>
      <pre class="nav-environment--local" *ngIf="isLocal()">&nbsp;LOCAL</pre>
      <pre class="nav-environment--debug" *ngIf="isDebugMode()">&nbsp;debug</pre>

    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarAportal" aria-controls="navbarAportal" aria-expanded="false"
            aria-label="Toggle navigation">
      <i class="fal fa-bars"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarAportal">
      <ul class="navbar-nav mr-auto">
        <li class="position-relative nav-item mr-l pl-xs {{(item.tree) ? 'dropdown' : ''}}" *ngFor="let item of main">
          <a class="nav-link dropdown-toggle pl-0" href="#" role="button" data-toggle="dropdown" aria-expanded="false"
             *ngIf="item.tree && item.showInNav && userService.canShowEinstellung(item)">{{item.navLinkName | translate}}</a>
          <div class="dropdown-menu" *ngIf="item.tree && item.showInNav && userService.canShowEinstellung(item)">
            <ng-container *ngFor="let subitem of item.tree">
              <a *ngIf="subitem.showInNav && subitem.type !== NavType.DEINEAPOTHEKESTORE && subitem.type !== NavType.PRIVATSORTIMENT && subitem.type !== NavType.PDF && subitem.type !== NavType.PROMO"
                 class="dropdown-item py-xxs"
                 routerLink="/{{subitem.path}}"
                 [queryParams]="subitem.extUrl ? {url: subitem.extUrl} : ''"
                 (click)="mtmEvent(subitem.navLinkName)">
                {{subitem.navLinkName | translate}}
              </a>

              <a class="dropdown-item py-xxs"
                 *ngIf="tokenLoading &&  subitem.type === NavType.DEINEAPOTHEKESTORE  && tokens.length === 1"
                 (click)="mtmEvent(subitem.navLinkName)"
                 [attr.target]="subitem.shouldOpenInNewTab ? '_blank' : null"
                 attr.data-cy="nav-item-{{subitem.dataCy}}"
                  href="{{getLocation(subitem.type)}}"
              >
                {{subitem.navLinkName | translate}} <i class="fal fa-external-link"></i>

              </a>

              <a class="dropdown-item py-xxs"
                 *ngIf="tokenLoading &&  subitem.type === NavType.PROMO"
                 (click)="showOpenProductSearchDialog(); mtmEvent(subitem.navLinkName)"
                 [attr.target]="subitem.shouldOpenInNewTab ? '_blank' : null"
                 attr.data-cy="nav-item-{{subitem.dataCy}}"
              >
                {{subitem.navLinkName | translate}}
              </a>
              <a class="dropdown-item py-xxs"
                 *ngIf="tokenLoading &&  subitem.type ===  NavType.PRIVATSORTIMENT"
                 (click)="mtmEvent(subitem.navLinkName)"
                 [attr.target]="subitem.shouldOpenInNewTab ? '_blank' : null"
                 attr.data-cy="nav-item-{{subitem.dataCy}}"
                  href="{{getLocation(subitem.type)}}"
              >
                {{subitem.navLinkName | translate}} <i class="fal fa-external-link"></i>

              </a>
              <a class="dropdown-item py-xxs"
                 *ngIf="tokenLoading && subitem.type === NavType.DEINEAPOTHEKESTORE && tokens.length > 1"
                 (click)="openIdfSelectionDialog(subitem.type, subitem.tileImageFileName, getHeading(subitem.type), getMessage(subitem.type));
                 mtmEvent((subitem.navLinkName ? subitem.navLinkName : subitem.heading))"
              >
                {{subitem.navLinkName | translate}} <i class="fal fa-external-link"></i>
              </a>

              <a class="dropdown-item py-xxs"
                 *ngIf="tokenLoading &&  subitem.type === NavType.PDF"
                 (click)="mtmEvent(subitem.navLinkName)"
                 [attr.target]="subitem.shouldOpenInNewTab ? '_blank' : null"
                 attr.data-cy="nav-item-{{subitem.dataCy}}"
                 href="{{subitem.path}}">
                {{subitem.navLinkName | translate}} <i class="fal fa-external-link"></i>
              </a>
            </ng-container>

          </div>
          <a *ngIf="!item.tree && item.showInNav " class="nav-link pl-0" routerLink="/{{item.path}}" [queryParams]="item.extUrl ? {url: item.extUrl} : ''">{{item.navLinkName | translate}}</a>
        </li>
        <li *ngIf="isAuthorized()" class="position-relative nav-item mr-l pl-xs dropdown">
          <a  class="nav-link dropdown-toggle pl-0" href="#" role="button" data-toggle="dropdown" aria-expanded="false">PHX Intern</a>
          <div class="dropdown-menu">
            <a [queryParams]="{url: '/index.php?id=19', heading: 'Vertriebsinformationen'}"
               [routerLink]="['/static-content']" class="dropdown-item py-xxs" onclick="document.getElementById('cms-content').src = document.getElementById('cms-content').src">
              <span>Vertriebsinfo</span>
            </a>
            <a class="dropdown-item py-xxs" (click)="toLindaGVL(); mtmEvent('LINDA GVL Signin')" *ngIf="sessionStorage.getItem('gvl')">
              <span>LINDA Vertrag<i class="fal fa-external-link"></i></span>
            </a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav align-items-center">
        <li class="nav-item">
          <app-idf-select></app-idf-select>
        </li>
        <li>
          <app-quick-nav></app-quick-nav>
        </li>
        <li>
          <app-header></app-header>
        </li>

      </ul>



    </div>
  </nav>
</div>



import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'public',
    templateUrl: './view-public.component.html',
    styleUrls: ['./view-public.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ViewPublicComponent implements OnInit {

    isIe = false;

    constructor() {
        this.checkBrowser();
    }

    ngOnInit() {
    }



    // Checking is user browser IE
    // Can be removed after upgrade to Angular 13
    checkBrowser() {
        const agent = window.navigator.userAgent.toLowerCase();
        if (agent.indexOf('trident') > -1) {
            this.isIe =  true;
        }
    }

}

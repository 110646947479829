import {Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MatomoService} from '../../../core/services/matomo.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DialogRestService} from '../../../core/services/api/dialog.rest-service';
import {DialogPopupInteface} from '../../../core/interfaces/dialog-popup.interface';
import {DOCUMENT} from '@angular/common';

const incr = 1;

@Component({
    selector: 'app-survey-dialog',
    templateUrl: './survey-dialog.component.html',
    styleUrls: ['./survey-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SurveyDialogComponent implements OnInit {
    private ngUnsubscribe$ = new Subject<any>();
    public subscribed = false;
    public hasPreOrdered = false;
    // update push notification related variables below
    public progress = 0;
    public updateListArray: string[] = [];
    public listItems = [];
    public progressBlock;
    public currentProgress = 0;
    public timer: any;
    public index = 0;
    public customTimerExist = null;
    public customUpdateListArray = [];
    cursorStyle = 'default';

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogPopupInteface,
                private dialogRef: MatDialogRef<SurveyDialogComponent>,
                private route: Router,
                public dialog: MatDialog,
                private matomoService: MatomoService,
                private dialogRestService: DialogRestService,
                @Inject(DOCUMENT) public document: Document,
    ) {
        if (data.dialog_popup_type === 'UPDATE') {
            this.dialogRef.disableClose = true;
            const parser = new DOMParser();
            const doc = parser.parseFromString(data.body, 'text/html');
            this.customTimerExist = doc.getElementById('customTimer');
            const liArray = doc.getElementsByTagName('li');
            Array.from(liArray).forEach((li) => {
                if (li.id.includes('showAt')) {
                    this.customUpdateListArray.push({
                        content: li.innerHTML,
                        timer: Number(li.id.split('showAt-')[1])
                    });
                } else {
                    this.updateListArray.push(li.innerHTML);

                }
            });
        }

        if (this.data.link != null) {
            this.cursorStyle = 'pointer';
        }
    }

    ngOnInit(): void {
        if (this.customTimerExist) {
            this.customUpdateListArray.sort((a, b) => a.timer - b.timer);
            this.timer = setInterval(() => this.manageProgressWithCustomTimer(), 100);
        } else {
            const noOfProgressBlock = this.updateListArray.length + 1;
            this.progressBlock = Math.ceil(100 / noOfProgressBlock);
            this.currentProgress = this.progressBlock;
            this.timer = setInterval(() => this.manageProgress(), 100);
        }
    }

    onImageClick(name) {
        this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'Klick auf Popup', name);
        // this.route.navigateByUrl(this.data.link);
        if (this.data.link != null) {
            window.open(this.data.link, this.data.target);
        }
        this.dialog.closeAll();
    }

    onDownloadClick(name) {
        this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'Klick auf Popup', name);
        // window.open('/assets/other/FAG_03_210x297_Phoenix_Zielgruppenfax_10-2022_02.pdf', '_blank');
        window.open(this.data.link1, this.data.target);
    }

    onButtonClickFirst(name) {
        this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'Klick auf Popup', name);
        // this.route.navigateByUrl(this.data.link);
        window.open(this.data.link1, this.data.target);
    }

    onButtonClickSecond(name) {
        this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'Klick auf Popup', name);
        // this.route.navigateByUrl(this.data.link);
        window.open(this.data.link2, this.data.target);
    }

    onLinkClick(name, pos) {
        this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'Klick auf Popup Link', name, pos);
    }

    onSubscribe(name) {
        this.dialogRestService.subscribeToDialogOffer(this.data.id).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
            if (res.return_object !== null && res.error_code === null) {
                this.subscribed = true;
                if (this.data.link != null) {
                    window.open(this.data.link, this.data.target);
                }
                this.dialog.closeAll();
            }
        });
        this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'Klick auf Popup Link', name, 1);

        if (name === 'show video') {
            window.open('/#/fragenundantworten','_self');
        }
    }

    onOptOut(name) {
        this.dialogRestService.optOutToDialogOffer(this.data.id).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
            this.dialog.closeAll();
        });
        this.matomoService.trackEvent('FEATUREBEZOGENE KPIs', 'Klick auf Popup Link', name, 0);
    }

    manageProgress() {
        if (this.progress === 100) {
            clearInterval(this.timer);
            [...this.document.querySelectorAll('.disabled') as any].map(x => {
                x.classList.remove('disabled');
                x.addEventListener('click', () => this.onSubscribe('update'));
            });

        } else if (this.progress === this.currentProgress) {
            if (this.index < this.updateListArray.length) {
                this.listItems.push(this.updateListArray[this.index]);
                this.currentProgress = this.currentProgress + this.progressBlock > 100 ? 100 : this.currentProgress + this.progressBlock;
                this.index++;
            }
        } else {
            this.progress = this.progress + incr;
        }
    }

    manageProgressWithCustomTimer() {
        if (this.progress === 100) {
            clearInterval(this.timer);
            [...this.document.querySelectorAll('.disabled') as any].map(x => {
                x.classList.remove('disabled');
                x.addEventListener('click', () => this.onSubscribe('update'));
            });
        } else if (this.index < this.customUpdateListArray.length && this.progress === this.customUpdateListArray[this.index]?.timer) {
            this.listItems.push(this.customUpdateListArray[this.index].content);
            this.index++;
        } else {
            this.progress = this.progress + incr;
        }

    }
}

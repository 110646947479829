export class ResponseSnakeModel {

    error_code: string;
    return_object: any;

    constructor(obj: any = {}) {

        this.error_code = obj && obj.error_code || null;
        this.return_object = obj && obj.return_object || null;
    }
}

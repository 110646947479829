<!--
*************
** Default **
*************
-->
<!--<ng-container *ngIf="!data.subscription">-->

<!--    <div *ngIf="data.highlight" class=" circle highlight text-center d-flex align-items-center justify-content-center">-->
<!--        <span [innerHTML]="data.highlight"></span>-->
<!--    </div>-->
<!--        <button class="btn btn-primary m-0 position-absolute " style="bottom:30px; left:30px" mat-button [mat-dialog-close]="false" (click)="onButtonClick()">{{data.button}}</button>-->
<!--<div class="dialog-top position-relative mb-xl">-->
<!--<img src="{{data.image}}" alt="{{data.image_alt}}"/>-->
<!--</div>-->
<!--        <button class="close" type="button" [mat-dialog-close]="true"><i class="fal fa-times"></i></button>-->

<!--<h1 mat-dialog-title class="pb-0 survey-title" [innerHTML]="data.title"></h1>-->
<!--<p [innerHTML]="data.title"></p>-->
<!--<div mat-dialog-content>-->
<!--    <p [innerHTML]="data.body"></p>-->
<!--  <p>Profitieren Sie schon heute von der digitalen Belegbereitstellung.<sup>*</sup></p>-->
<!--  <p><img src="../../assets/img/icons/package-fast.svg" alt="" width="40">&nbsp;&nbsp;&nbsp;Schnellere und präzisere Bestellabwicklung</p>-->
<!--  <p><img src="../../assets/img/icons/list.svg" alt="" width="40">&nbsp;&nbsp;&nbsp;Archivierung wichtiger Dokumente</p>-->
<!--  <p><img src="../../assets/img/icons/world.svg" alt="" width="40">&nbsp;&nbsp;&nbsp;Gemeinsam Nachhaltigkeit für eine grünere Zukunft</p>-->
<!--  <p>Rufen Sie in Ihrem PHOENIX Kunden-Service-Center an und wir veranlassen für Sie gerne die sofortige Deaktivierung-->
<!--    der physischen Lieferscheinbeigabe.</p>-->
<!--</div>-->

<!--<div mat-dialog-actions class="text-center my-4 d-flex justify-content-center align-items-center" *ngIf="data.button">-->
<!--    <button class="btn btn-primary mt-0 btn-special">{{data.button}}</button>-->
<!--    <button class="btn btn-primary mt-0" mat-button [mat-dialog-close]="false" (click)="onButtonClick()">{{data.button}}</button>-->
<!--    <button class="btn btn-secondary" mat-button [mat-dialog-close]="true">Schließen</button>-->
<!--</div>-->

<!--<p *ngIf="data.disclaimer" class="disclaimer" [innerHTML]="data.disclaimer"></p>-->
<!--</ng-container>-->
<!--

*****************************
** POPUP WITH PDF DOWNLOAD **
*****************************
-->
<ng-container *ngIf="!data.subscription && data.title !== 'UPDATE'">
<div class="dialog-top position-relative" >
  <img src="{{data.image}}" alt="{{data.image_alt}}"/>
  <!--     <div *ngIf="data.highlight" class=" circle highlight text-center d-flex align-items-center justify-content-center">-->
  <!--     <span [innerHTML]="data.highlight"></span>-->
</div>


<h2 class="mt-xxl mb-0" [innerHTML]="data.title" *ngIf="data.title"></h2>
<div mat-dialog-content>
<p [innerHTML]="data.body" *ngIf="data.body"></p>
</div>
<div mat-dialog-actions class="text-center my-4 d-flex justify-content-center align-items-center" *ngIf="data.button">
   <button class="btn btn-primary cursor-pointer mt-0" mat-button [mat-dialog-close]="false" (click)="onImageClick(data.image_alt)">{{data.button}}</button>
</div>
</ng-container>

<!--
****************************
** POPUP WITH SUB/OPT-OUT **
****************************
-->
<ng-container *ngIf="data.subscription">

  <ng-container *ngIf="!subscribed">
  <div class="dialog-top">
    <img src="{{data.image}}" alt="{{data.image_alt}}">
    <!--    <div class="circle highlight text-center d-flex align-items-center justify-content-center">-->
    <!--        <span [innerHTML]="data.highlight"></span>-->
    <!--    </div>-->
    <!--    <button class="close" type="button" [mat-dialog-close]="true"><i class="fal fa-times"></i></button>-->
  </div>

    <h2 class="mt-xxl mb-0" [innerHTML]="data.title" *ngIf="data.title"></h2>
    <div mat-dialog-content>
      <p [innerHTML]="data.body" *ngIf="data.body"></p>
    </div>
    <div mat-dialog-actions class="text-center my-4 d-flex justify-content-center align-items-center" *ngIf="data.button">
      <button class="btn btn-primary cursor-pointer mt-0" mat-button [mat-dialog-close]="false" (click)="onSubscribe(data.image_alt)">{{data.button}}</button>
    </div>
<!--    <div mat-dialog-title>-->
<!--      <h4 class="phx-corporate" [innerHTML]="data.title"></h4>-->
<!--    </div>-->
<!--        <h2 class="mt-xxl phx-corporate text-center" [innerHTML]="data.title"></h2>-->

<!--    <p class="text-center">-->
<!--      Aufgrund einer aktualisierten Version der DATEV Schnittstelle ist eine erneute Bestätigung der Zugriffsberechtigung auf Ihren Bestand erforderlich. Mit der von Ihnen genutzten alten Version können ab April 2024 keine PHOENIX Belege mehr an DATEV übertragen werden.-->
<!--    </p>-->

<!--    <p class="text-center text-danger"><strong>Bestätigen Sie einmalig Ihre DATEV Berechtigungen bis zum 31.03.2024!</strong></p>-->

<!--    <div mat-dialog-actions class="mb-s  d-flex justify-content-center">-->
<!--      <button class="btn-primary" mat-button (click)="onSubscribe(data.image_alt)">-->
<!--        <span>Jetzt bestätigen</span>-->
<!--&lt;!&ndash;        <i class="material-icons">&ndash;&gt;-->
<!--&lt;!&ndash;          check&ndash;&gt;-->
<!--&lt;!&ndash;        </i>&ndash;&gt;-->
<!--      </button>-->

<!--      <button class="btn-secondary" mat-button (click)="onOptOut(data.image_alt)">-->
<!--        <span>Später erinnern</span>-->
<!--&lt;!&ndash;        <i class="material-icons">&ndash;&gt;-->
<!--&lt;!&ndash;          close&ndash;&gt;-->
<!--&lt;!&ndash;        </i>&ndash;&gt;-->
<!--      </button>-->

<!--    </div>-->
  </ng-container>
<!--  <ng-container *ngIf="subscribed">-->
<!--    <p class="phx-cta mt-m"><b>Vielen Dank für Ihren Antrag!<br>-->
<!--      Wir werden die Umstellung auf die elektronische Sammelrechnung-->
<!--      zum nächsten Monatswechsel durchführen</b></p>-->
<!--  </ng-container>-->
</ng-container>

<!--
****************************************
** PUSH NOTIFICATION WITH SUB/OPT-OUT **
****************************************
-->

<ng-container *ngIf="!data.subscription && data.title === 'UPDATE'">
  <div class="d-flex align-items-center mb-l">
    <i class="fal fa-sync phx-corporate mr-3 updateIcon"></i>
    <h1 class="phx-corporate m-0 p-0" [innerHTML]="data.title"></h1>
  </div>
  <h6 class="updateHead">Ihr PHOENIX Apothekenportal wird auf eine neue Version aktualisiert.</h6>
  <p>Bitte warten Sie, bis die Installation abgeschlossen ist. Anschließend können Sie das Fenster schließen und die neuen Funktionen nutzen.</p>
  <div mat-dialog-content>
    <div class="d-flex align-items-center">
      <mat-progress-bar mode="determinate" color="accent" [value]="progress" class="my-xl"></mat-progress-bar>
      <h6 class="loadingPercentage" [ngStyle]="{'left': progress + '%'}">{{progress}}%</h6>
      <small class="ml-m phx-corporate font-weight-bold" *ngIf="progress < 100">Loading...</small>
    </div>
    <div>
    </div>
    <h6 class="updateHead">NEU:</h6>
    <ul class="check-list mb-0">
      <ng-container *ngFor="let content of listItems ; let i = index">
        <li [innerHTML]="content | safeHtml"></li>
      </ng-container>
    </ul>
    <div class="dotLoader" *ngIf="progress < 100"></div>
  </div>
  <div mat-dialog-actions class="mb-s  d-flex justify-content-start">
    <button class="btn btn-primary" mat-button [mat-dialog-close]="true" (click)="onSubscribe('update')" [disabled]="progress < 100" >{{data.button}}</button>
  </div>
  <p *ngIf="data.disclaimer" class="disclaimer" [innerHTML]="data.disclaimer"></p>
</ng-container>

import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import * as fromReducers from './core/store/reducers';
import {NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'PHOENIX Apothekenportal';

    constructor(@Inject(TranslateService) public translate: TranslateService,
                public router: Router,
                public store: Store<fromReducers.State>,
                private titleService: Title) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const name = event.url.replace('/', '').trim().toUpperCase();
                this.titleService.setTitle(this.title);
                const location = event.urlAfterRedirects;
            }
        });
        translate.addLangs(['de']);
        translate.setDefaultLang('de');
    }

    ngOnInit() {
        this.titleService.setTitle(this.title);
        for (let i = 0; i < 1000; i++) {
            clearInterval(i);
        }
    }
}
